import React from 'react';
import '../style/ImageModal.css'; // Add some basic styling for the modal

const ImageModal = ({ imageSrc, onClose }) => {
    if (!imageSrc) return null; // Don't render the modal if no image source is provided

    return (
        <div className="image-modal-overlay" onClick={onClose}>
            <div className="image-modal-content" onClick={(e) => e.stopPropagation()}>
                <img src={imageSrc} alt="Design" className="large-image" />
                <button className="close-modal" onClick={onClose}>Close</button>
            </div>
        </div>
    );
};

export default ImageModal;
