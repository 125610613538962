import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import Header from '../components/Header';
import '../style/Product.css';
import Sidebar from '../components/Sidebar';

const tShirts = [
  {
    id: 1,
    name: 'White Oversized T-shirt',
    image: 'whiteF5.png', // Replace with actual path
  },
  {
    id: 2,
    name: 'Black Oversized T-shirt',
    image: 'blackF1.png', // Replace with actual path
  },
];

const SelectTShirt = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const shop = queryParams.get('shop');

  const handleTShirtClick = (color) => {
    navigate(`/SelectProduct?shop=${encodeURIComponent(shop)}`, { state: { color } });
  };

  return (
    <div className="dashboard">
      <Header />
      <div className="dashboard-container">
        <Sidebar />
        <div className="main-content">
          <div className="product-list">
            {tShirts.map((tShirt) => (
              <div
                key={tShirt.id}
                className="product-card"
                onClick={() => handleTShirtClick(tShirt.name.split(' ')[0].toLowerCase())}
              >
                <img src={tShirt.image} alt={tShirt.name} className="product-image" />
                <p className="tshirt-name">{tShirt.name}</p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SelectTShirt;