import React from 'react';
import Header from '../components/Header';
import Sidebar from '../components/Sidebar';
import '../style/PrivacyPolicy.css'; // Reuse PrivacyPolicy.css for consistency

const Cancellation = () => {
  return (
    <div className="privacy-page">
      <Header />
      <div className="privacy-container">
        <Sidebar />
        <div className="main-content">
          <h1>Cancellation and Refund Policy</h1>

          <h2>1. Order Cancellation and Modifications</h2>
          <p>
            Each piece of artwork we present is a unique masterpiece crafted by our talented artists. We understand that circumstances may change, so you have the flexibility to cancel or modify your order before it moves into the production phase. Once production begins, our team diligently brings the artist's vision to life, making it challenging to cancel the order without potential charges.
          </p>
          <p>
            To check the status of your order, make adjustments to the shipping address, or explore cancellation and modification options, please visit the <strong>Contact Us</strong> section on the Unitee website.
          </p>

          <h2>2. Refund Eligibility</h2>
          <p>
            Refunds may be issued under certain conditions, such as:
          </p>
          <ul>
            <li>If the product is damaged during shipping (please notify us within 48 hours of delivery with a photo of the damaged product).</li>
            <li>If the product is defective or not as described, we will gladly process a return or exchange.</li>
            <li>If the customer received the wrong product, we will arrange for a return and issue a refund or send a replacement product.</li>
          </ul>

          <h2>3. Non-Refundable Items</h2>
          <p>The following items are not eligible for a refund or return:
          </p>
          <ul>
            <li>Customized or personalized products that were made to order according to customer specifications.</li>
            <li>Products returned after 14 days of receiving the item.</li>
            <li>Items that have been used or damaged by the customer after delivery.</li>
          </ul>

          <h2>4. How to Request a Refund</h2>
          <p>If you are eligible for a refund, please follow these steps:</p>
          <ul>
            <li>Contact our support team at <a href="mailto:contact@uniteeculture.com">contact@uniteeculture.com</a> with your order number and details of the issue.</li>
            <li>Provide a photo of the damaged or incorrect item if applicable.</li>
            <li>Our support team will review the request and guide you through the refund process.</li>
          </ul>

          <h2>5. Shipping Costs</h2>
          <p>Shipping costs are non-refundable, except in cases where we have made an error or the product is defective.</p>

          <h2>6. Processing Time</h2>
          <p>Refunds may take up to 14 business days to process, depending on the payment method used and your bank's policies.</p>

          <h2>7. Contact Us</h2>
          <p>If you have any questions about our Return and Refund Policy, please don't hesitate to reach out to us:</p>
          <p>
            <strong>Unitee Studios Private Limited</strong><br />
            Email: <a href="mailto:contact@uniteeculture.com">contact@uniteeculture.com</a><br />
            Phone: +91 8104038187<br />
            302 Red Rose, Mumbai 400052
          </p>
        </div>
      </div>
    </div>
  );
};

export default Cancellation;
