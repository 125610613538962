import React from 'react';
import Header from '../components/Header';
import Sidebar from '../components/Sidebar';
import '../style/PrivacyPolicy.css'; // Ensure to import the CSS file

const PrivacyPolicy = () => {
  return (
    <div className="privacy-page">
      <Header />
      <div className="privacy-container">
        <Sidebar />
        <div className="main-content">
          <h1>Privacy Policy</h1>
          <p><strong>Effective Date:</strong> November 2, 2024</p>

          <h2>1. Introduction</h2>
          <p>
            Welcome to Unitee (“we,” “our,” or “us”). We respect your privacy and are committed to protecting your personal information. This Privacy Policy outlines our practices regarding the collection, use, and sharing of your information when you use our platform.
          </p>

          <h2>2. Information We Collect</h2>
          <p>We may collect the following types of information:</p>
          <ul>
            <li><strong>Personal Information:</strong> Name, email address, phone number, and billing information when you create an account.</li>
            <li><strong>Usage Data:</strong> Information about how you interact with our platform, including IP addresses, device type, browser type, and pages visited.</li>
            <li><strong>Shopify Store Data:</strong> When you connect your Shopify store to our platform, we may access certain data from your store, including:
              <ul>
                <li><strong>Orders:</strong> Details of orders placed in your store, including order amounts, products ordered, and order statuses.</li>
                <li><strong>Customer Information:</strong> Contact information and order history of customers who make purchases on your store.</li>
                <li><strong>Products:</strong> Information on products listed in your store, including descriptions, images, and pricing.</li>
                <li><strong>Inventory:</strong> Data related to product stock levels and availability.</li>
              </ul>
            </li>
          </ul>

          <h2>3. How We Use Your Information</h2>
          <p>We use your information to:</p>
          <ul>
            <li>Provide and improve our services.</li>
            <li>Process and fulfill orders.</li>
            <li>Sync data with your Shopify store to ensure accurate order processing and inventory management.</li>
            <li>Communicate with you regarding account or customer service issues.</li>
            <li>Comply with legal obligations.</li>
          </ul>

          <h2>4. Sharing Your Information</h2>
          <p>We may share your information with:</p>
          <ul>
            <li><strong>Service Providers:</strong> We work with third-party companies to assist with key business functions. For example, we use a third-party payment processor to facilitate secure wallet transactions on our platform. Additionally, we use a third-party shipping provider to manage and track shipping logistics.</li>
            <li><strong>Legal Requirements:</strong> In response to a court order or other governmental request, or to comply with legal obligations.</li>
          </ul>

          <h2>5. Security</h2>
          <p>We take reasonable measures to protect your personal information against unauthorized access, alteration, or disclosure. However, no internet-based platform can be entirely secure.</p>

          <h2>6. Cookies and Tracking Technologies</h2>
          <p>Our platform uses cookies and similar technologies to enhance user experience and track usage.</p>

          <h2>7. Your Rights</h2>
          <p>Depending on your location, you may have the right to access, correct, delete, or restrict the use of your personal information. Contact us at <a href="mailto:contact@uniteeculture.com">contact@uniteeculture.com</a> for requests.</p>

          <h2>8. Changes to This Privacy Policy</h2>
          <p>We may update this Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page.</p>

          <h2>9. Copyright and Intellectual Property</h2>
          <p>Our platform allows users to upload and use their own content, such as designs, logos, images, and other potentially copyrightable material. By uploading content to our platform, you affirm that you own the rights to this material or have obtained permission to use it. You permit us to use the designs solely for the purpose of processing orders and fulfilling your requests. We are not liable for any copyright or intellectual property infringements arising from the use of such content. You are solely responsible for ensuring that all content complies with copyright, trademark, and other applicable intellectual property laws.</p>

          <h2>10. Contact Us</h2>
          <p>If you have questions or concerns about this Privacy Policy, please contact us at:</p>
          <p>
            <strong>Unitee Studios Private Limited</strong><br />
            Email: <a href="mailto:contact@uniteeculture.com">contact@uniteeculture.com</a><br />
            Phone: +91 8104038187<br />
            302 Red Rose, Mumbai 400052
          </p>
        </div>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
