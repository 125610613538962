

// import React, { useState } from 'react';
// import { useNavigate, Link, useLocation } from 'react-router-dom';
// import { db, auth } from '../firebase.js';
// import { doc, setDoc, serverTimestamp } from 'firebase/firestore';
// import { createUserWithEmailAndPassword } from 'firebase/auth';

// const SignupPage = () => {
//     const location = useLocation();
//     const navigate = useNavigate();
//     const queryParams = new URLSearchParams(location.search);
//     const shop = queryParams.get('shop'); // Assuming shop name is passed in the URL as a query parameter

//     const [brandName, setBrandName] = useState('');
//     const [phoneNumber, setPhoneNumber] = useState('');
//     const [emailAddress, setEmailAddress] = useState('');
//     const [gstNumber, setGstNumber] = useState('');
    
//     const [address, setAddress] = useState('');
//     const [city, setCity] = useState('');
//     const [state, setState] = useState('');
//     const [pincode, setPincode] = useState('');

//     const [password, setPassword] = useState('');
//     const [confirmPassword, setConfirmPassword] = useState('');
//     const [error, setError] = useState('');
//     const [success, setSuccess] = useState('');

//     const handleSubmit = async (e) => {
//         e.preventDefault();
//         setError('');
//         setSuccess('');

//         // Check if passwords match
//         if (password !== confirmPassword) {
//             setError('Passwords do not match.');
//             return;
//         }

//         if (!shop) {
//             setError('Shop name is required.');
//             return;
//         }

//         try {
//             // Create user with Firebase Authentication
//             const userCredential = await createUserWithEmailAndPassword(auth, emailAddress, password);
//             const user = userCredential.user;  // User UID from Firebase Authentication

//             // Reference to the specific shop document in Firestore, using the shop name as document ID
//             const shopRef = doc(db, 'shops', shop);

//             // Set the profile information in Firestore under the shop document
//             await setDoc(shopRef, {
//                 brandName,
//                 phoneNumber,
//                 emailAddress,
//                 gstNumber,
//                 address,
//                 city,
//                 state,
//                 pincode,
//                 uid: user.uid,  // Associate the user UID with the shop
//                 profileUpdatedAt: serverTimestamp(),
//             });

//             setSuccess('Signup successful! Redirecting...');

//             // Redirect to OrdersPage after a short delay
//             setTimeout(() => {
//                 navigate(`/Dashboard?shop=${encodeURIComponent(shop)}`);
//             }, 2000);
//         } catch (err) {
//             console.error('Error signing up:', err);
//             setError('An error occurred. Please try again.');
//         }
//     };

//     return (
//         <div style={styles.container}>
//             <h1 style={styles.heading}>Signup</h1>
//             <form onSubmit={handleSubmit} style={styles.form}>
//                 <label style={styles.label}>
//                     Brand Name:
//                     <input
//                         type="text"
//                         value={brandName}
//                         onChange={(e) => setBrandName(e.target.value)}
//                         required
//                         style={styles.input}
//                     />
//                 </label>
//                 <label style={styles.label}>
//                     Phone Number:
//                     <input
//                         type="tel"
//                         value={phoneNumber}
//                         onChange={(e) => setPhoneNumber(e.target.value)}
//                         required
//                         pattern="[0-9]{10}"
//                         title="Please enter a valid 10-digit phone number."
//                         style={styles.input}
//                     />
//                 </label>
//                 <label style={styles.label}>
//                     Email Address:
//                     <input
//                         type="email"
//                         value={emailAddress}
//                         onChange={(e) => setEmailAddress(e.target.value)}
//                         required
//                         style={styles.input}
//                     />
//                 </label>
//                 <label style={styles.label}>
//                     GST Number:
//                     <input
//                         type="text"
//                         value={gstNumber}
//                         onChange={(e) => setGstNumber(e.target.value)}
//                         pattern="[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}"
//                         title="Please enter a valid GST number."
//                         style={styles.input}
//                     />
//                 </label>

//                 <label style={styles.label}>
//                     Address:
//                     <input
//                         type="text"
//                         value={address}
//                         onChange={(e) => setAddress(e.target.value)}
//                         required
//                         style={styles.input}
//                     />
//                 </label>


//                 <label style={styles.label}>
//                     City:
//                     <input
//                         type="text"
//                         value={city}
//                         onChange={(e) => setCity(e.target.value)}
//                         required
//                         style={styles.input}
//                     />
//                 </label>

//                 <label style={styles.label}>
//                     State:
//                     <input
//                         type="text"
//                         value={state}
//                         onChange={(e) => setState(e.target.value)}
//                         required
//                         style={styles.input}
//                     />
//                 </label>

                
//                 <label style={styles.label}>
//                     Pincode:
//                     <input
//                         type="text"
//                         value={pincode}
//                         onChange={(e) => setPincode(e.target.value)}
//                         required
//                         style={styles.input}
//                     />
//                 </label>

//                 <label style={styles.label}>
//                     Password:
//                     <input
//                         type="password"
//                         value={password}
//                         onChange={(e) => setPassword(e.target.value)}
//                         required
//                         minLength={6}
//                         style={styles.input}
//                     />
//                 </label>
//                 <label style={styles.label}>
//                     Confirm Password:
//                     <input
//                         type="password"
//                         value={confirmPassword}
//                         onChange={(e) => setConfirmPassword(e.target.value)}
//                         required
//                         minLength={6}
//                         style={styles.input}
//                     />
//                 </label>
//                 <button type="submit" style={styles.button}>Sign Up</button>
//                 {error && <p style={styles.error}>{error}</p>}
//                 {success && <p style={styles.success}>{success}</p>}
//             </form>
//             <p style={styles.text}>
//                 Already have an account? <Link to={`/?shop=${encodeURIComponent(shop)}`} style={styles.link}>Login</Link>
//             </p>
//         </div>
//     );
// };

// const styles = {
//     container: {
//         maxWidth: '500px',
//         margin: '50px auto',
//         padding: '20px',
//         border: '1px solid #ccc',
//         borderRadius: '10px',
//         boxShadow: '0 0 10px rgba(0,0,0,0.1)',
//     },
//     heading: {
//         textAlign: 'center',
//         marginBottom: '20px',
//         color: '#4CAF50',
//     },
//     form: {
//         display: 'flex',
//         flexDirection: 'column',
//     },
//     label: {
//         marginBottom: '15px',
//         fontSize: '1em',
//     },
//     input: {
//         width: '100%',
//         padding: '10px',
//         marginTop: '5px',
//         borderRadius: '5px',
//         border: '1px solid #ccc',
//         fontSize: '1em',
//     },
//     button: {
//         padding: '10px',
//         borderRadius: '5px',
//         border: 'none',
//         backgroundColor: '#4CAF50',
//         color: 'white',
//         fontSize: '1em',
//         cursor: 'pointer',
//     },
//     error: {
//         color: 'red',
//         marginTop: '10px',
//         textAlign: 'center',
//     },
//     success: {
//         color: 'green',
//         marginTop: '10px',
//         textAlign: 'center',
//     },
//     text: {
//         textAlign: 'center',
//         marginTop: '10px',
//     },
//     link: {
//         color: '#4CAF50',
//         textDecoration: 'none',
//     },
// };

// export default SignupPage;


import React, { useState } from 'react';
import { useNavigate, Link, useLocation } from 'react-router-dom';
import { db, auth } from '../firebase.js';
import { doc, setDoc, serverTimestamp } from 'firebase/firestore';
import { createUserWithEmailAndPassword, sendEmailVerification } from 'firebase/auth';

const SignupPage = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const queryParams = new URLSearchParams(location.search);
    const shop = queryParams.get('shop'); // Assuming shop name is passed in the URL as a query parameter

    const [brandName, setBrandName] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [emailAddress, setEmailAddress] = useState('');
    const [gstNumber, setGstNumber] = useState('');
    const [address, setAddress] = useState('');
    const [city, setCity] = useState('');
    const [state, setState] = useState('');
    const [pincode, setPincode] = useState('');

    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');
    const [emailSent, setEmailSent] = useState(false);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setError('');
        setSuccess('');
        setEmailSent(false);

        // Check if passwords match
        if (password !== confirmPassword) {
            setError('Passwords do not match.');
            return;
        }

        if (!shop) {
            setError('Shop name is required.');
            return;
        }

        try {
            // Create user with Firebase Authentication
            const userCredential = await createUserWithEmailAndPassword(auth, emailAddress, password);
            const user = userCredential.user;  // User UID from Firebase Authentication

            // Send verification email
            await sendEmailVerification(user);

            setEmailSent(true);

            // Reference to the specific shop document in Firestore, using the shop name as document ID
            const shopRef = doc(db, 'shops', shop);

            // Set the profile information in Firestore under the shop document
            await setDoc(shopRef, {
                brandName,
                phoneNumber,
                emailAddress,
                gstNumber,
                address,
                city,
                state,
                pincode,
                uid: user.uid,  // Associate the user UID with the shop
                profileUpdatedAt: serverTimestamp(),
            });

            setSuccess('Signup successful! Please check your email to verify your account.');

            // Redirect to a "Please Verify" page or leave on the same page for the user to verify their email
            // For now, we're just setting success, but you may want to redirect somewhere else
        } catch (err) {
            console.error('Error signing up:', err);
            setError('An error occurred. Please try again.');
        }
    };

    return (
        <div style={styles.container}>
            <h1 style={styles.heading}>Signup</h1>
            <form onSubmit={handleSubmit} style={styles.form}>
                <label style={styles.label}>
                    Brand Name:
                    <input
                        type="text"
                        value={brandName}
                        onChange={(e) => setBrandName(e.target.value)}
                        required
                        style={styles.input}
                    />
                </label>
                <label style={styles.label}>
                    Phone Number:
                    <input
                        type="tel"
                        value={phoneNumber}
                        onChange={(e) => setPhoneNumber(e.target.value)}
                        required
                        pattern="[0-9]{10}"
                        title="Please enter a valid 10-digit phone number."
                        style={styles.input}
                    />
                </label>
                <label style={styles.label}>
                    Email Address:
                    <input
                        type="email"
                        value={emailAddress}
                        onChange={(e) => setEmailAddress(e.target.value)}
                        required
                        style={styles.input}
                    />
                </label>
                <label style={styles.label}>
                    GST Number:
                    <input
                        type="text"
                        value={gstNumber}
                        onChange={(e) => setGstNumber(e.target.value)}
                        pattern="[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}"
                        title="Please enter a valid GST number."
                        style={styles.input}
                    />
                </label>

                <label style={styles.label}>
                    Address:
                    <input
                        type="text"
                        value={address}
                        onChange={(e) => setAddress(e.target.value)}
                        required
                        style={styles.input}
                    />
                </label> 

                <label style={styles.label}>
                    City:
                    <input
                        type="text"
                        value={city}
                        onChange={(e) => setCity(e.target.value)}
                        required
                        style={styles.input}
                    />
                </label> 

               <label style={styles.label}>
                    State:
                    <input
                        type="text"
                        value={state}
                        onChange={(e) => setState(e.target.value)}
                        required
                        style={styles.input}
                    />
                </label> 

                <label style={styles.label}>
                    Pincode:
                    <input
                        type="text"
                        value={pincode}
                        onChange={(e) => setPincode(e.target.value)}
                        required
                        style={styles.input}
                    />
                </label>

                <label style={styles.label}>
                    Password:
                    <input
                        type="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        required
                        minLength={6}
                        style={styles.input}
                    />
                </label>
                <label style={styles.label}>
                    Confirm Password:
                    <input
                        type="password"
                        value={confirmPassword}
                        onChange={(e) => setConfirmPassword(e.target.value)}
                        required
                        minLength={6}
                        style={styles.input}
                    />
                </label>
                <button type="submit" style={styles.button}>Sign Up</button>

                {error && <p style={styles.error}>{error}</p>}
                {success && !emailSent && <p style={styles.success}>{success}</p>}
                {emailSent && <p style={styles.success}>A verification email has been sent to your email address. Please verify your email to complete the registration.</p>}
            </form>
            <p style={styles.text}>
                Already have an account? <Link to={`/login?shop=${encodeURIComponent(shop)}`} style={styles.link}>Login</Link>
            </p>
        </div>
    );
};

const styles = {
    container: {
        maxWidth: '500px',
        margin: '50px auto',
        padding: '20px',
        border: '1px solid #ccc',
        borderRadius: '10px',
        boxShadow: '0 0 10px rgba(0,0,0,0.1)',
    },
    heading: {
        textAlign: 'center',
        marginBottom: '20px',
        color: '#000',
    },
    form: {
        display: 'flex',
        flexDirection: 'column',
    },
    label: {
        marginBottom: '15px',
        fontSize: '1em',
    },
    input: {
        width: '100%',
        padding: '10px',
        marginTop: '5px',
        borderRadius: '5px',
        border: '1px solid #ccc',
        fontSize: '1em',
    },
    button: {
        padding: '10px',
        borderRadius: '5px',
        border: 'none',
        backgroundColor: '#000',
        color: 'white',
        fontSize: '1em',
        cursor: 'pointer',
    },
    error: {
        color: 'red',
        marginTop: '10px',
        textAlign: 'center',
    },
    success: {
        color: 'green',
        marginTop: '10px',
        textAlign: 'center',
    },
    text: {
        textAlign: 'center',
        marginTop: '10px',
    },
    link: {
        color: '#000',
        textDecoration: 'none',
    },
};

export default SignupPage;

