import React, { useState } from 'react';
import {addDoc, collection } from 'firebase/firestore'; 
import { db, auth } from '../firebase';
import '../style/ContactUs.css';
import Header from '../components/Header';  
import Sidebar from '../components/Sidebar'; 

const ContactUs = () => {
    // State to manage form input values
    const [formData, setFormData] = useState({
      name: '',
      email: '',
      phone: '',
      message: '',
    });
  
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [status, setStatus] = useState('');
  
    // Handle input change and update state
    const handleChange = (e) => {
      const { name, value } = e.target;
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    };
  
    // Handle form submission
    const handleSubmit = async (e) => {
      e.preventDefault();
      setIsSubmitting(true);
      setStatus('');
  
      try {
        // Add contact form data to Firestore
        const docRef = await addDoc(collection(db, 'contactMessages'), {
          name: formData.name,
          email: formData.email,
          phone: formData.phone,
          message: formData.message,
          timestamp: new Date(),
        });
  
        console.log("Document written with ID: ", docRef.id);
  
        setStatus('Message sent successfully!');
        setFormData({
          name: '',
          email: '',
          phone: '',
          message: '',
        });
      } catch (error) {
        setStatus('Error sending message. Please try again later.');
        console.error("Error adding document: ", error);
      } finally {
        setIsSubmitting(false);
      }
    };
  
    return (
      <div className="contact-us">
        <Header />
        <div className="contact-container">
          <Sidebar />
          <div className="main-content">
            <h1>Get in Touch</h1>
            <p>Need assistance? We're just a message away.</p>
  
            <form onSubmit={handleSubmit} className="contact-form">
              <div className="form-group">
                <label htmlFor="name">Name</label>
                <input
                  type="text"
                  id="name"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                  required
                />
              </div>
  
              <div className="form-group">
                <label htmlFor="email">Email</label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  required
                />
              </div>
  
              <div className="form-group">
                <label htmlFor="phone">Phone Number</label>
                <input
                  type="text"
                  id="phone"
                  name="phone"
                  value={formData.phone}
                  onChange={handleChange}
                  required
                />
              </div>
  
              <div className="form-group">
                <label htmlFor="message">Message</label>
                <textarea
                  id="message"
                  name="message"
                  value={formData.message}
                  onChange={handleChange}
                  required
                ></textarea>
              </div>
  
              <button type="submit" disabled={isSubmitting}>Send</button>
  
              {status && <p className="status-message">{status}</p>}
            </form>
          </div>
        </div>
      </div>
    );
  };
  
  export default ContactUs;

