import React, { useState } from 'react';
import BACKEND_URL from '../config'
const StoreUrlForm = () => {
  const [storeUrl, setStoreUrl] = useState('');
  const [error, setError] = useState('');
  // Validate and format the Shopify store URL
  const validateAndFormatUrl = (url) => {
    let formattedUrl = url.trim().toLowerCase();
    // Add https:// if the URL doesn't start with it
    if (!/^https?:\/\//i.test(formattedUrl)) {
      formattedUrl = 'https://' + formattedUrl;
    }
    // Ensure it's a valid URL
    try {
      new URL(formattedUrl);
      return formattedUrl;
    } catch {
      throw new Error('Invalid URL format');
    }
  };
  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(''); // Clear any previous errors
    try {
      // Validate and format the Shopify store URL
      const formattedUrl = validateAndFormatUrl(storeUrl);
      const shop = new URL(formattedUrl).hostname;
      // **Use environment variable for backend URL**
      const backendUrl = BACKEND_URL;
      ;
      if (!backendUrl) {
        throw new Error('Backend URL is not defined');
      }
      // Redirect to your backend's auth route (which will redirect to Shopify)
      window.location.href = `${backendUrl}/api/auth?shop=${shop}`;
    } catch (error) {
      // Handle URL format error or other issues
      if (error.message === 'Invalid URL format') {
        setError('Please enter a valid Shopify store URL');
      } else {
        console.error('Error:', error);
        setError('An error occurred. Please try again.');
      }
    }
  };
  
    return (
      <div style={styles.container}>
        <h1 style={styles.header}>Welcome to Unitee</h1>
        <form onSubmit={handleSubmit} style={styles.form}>
          <input
            type="text"
            value={storeUrl}
            onChange={(e) => setStoreUrl(e.target.value)}
            placeholder="Enter your Shopify store URL"
            required
            style={styles.input}
          />
          <button type="submit" style={styles.button}>Connect</button>
          {error && <p style={styles.error}>{error}</p>}
        </form>
      </div>
    );
  };
  
  const styles = {
    container: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      minHeight: '100vh',
      backgroundColor: '#f9f9f9', // Light background color
      padding: '20px',
    },
    header: {
      fontSize: '2.5em',
      fontWeight: '700',
      color: '#333',
      marginBottom: '40px', // Space between the header and the form
      textAlign: 'center',
      textTransform: 'uppercase',
    },
    form: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      backgroundColor: 'white', // White background for the form
      padding: '40px',
      borderRadius: '10px',
      boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    },
    input: {
      padding: '10px',
      width: '300px',
      marginBottom: '20px',
      borderRadius: '5px',
      border: '1px solid #ccc',
      fontSize: '1em',
    },
    button: {
      padding: '10px 20px',
      borderRadius: '5px',
      border: 'none',
      backgroundColor: '#000',
      color: 'white',
      fontSize: '1em',
      cursor: 'pointer',
      transition: 'background-color 0.3s ease', // Smooth hover effect
    },
    buttonHover: {
      backgroundColor: '#45a049', // Darker shade for hover
    },
    error: {
      color: 'red',
      marginTop: '10px',
    }
  };
  
  export default StoreUrlForm;