import React, { useState } from 'react';
import { useNavigate, Link, useLocation } from 'react-router-dom';
import { auth, db } from '../firebase.js';
import { signInWithEmailAndPassword } from 'firebase/auth';
import {  doc, getDoc } from 'firebase/firestore';


const Login = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const queryParams = new URLSearchParams(location.search);
    const shop = queryParams.get('shop');

    const [emailAddress, setEmailAddress] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');

    // const handleSubmit = async (e) => {
    //     e.preventDefault();
    //     setError('');

    //     try {
    //         // Sign in the user with Firebase Authentication
    //         const userCredential = await signInWithEmailAndPassword(auth, emailAddress, password);
    //         const user = userCredential.user;

    //         // Redirect to OrdersPage after successful login
    //         navigate(`/dashboard?shop=${encodeURIComponent(shop)}`);
    //     } catch (err) {
    //         console.error('Error signing in:', err);
    //         setError('Invalid email or password. Please try again.');
    //     }
    // };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setError('');  // Reset any previous error message
    
        try {
            // Sign in the user with Firebase Authentication
            const userCredential = await signInWithEmailAndPassword(auth, emailAddress, password);
            const user = userCredential.user;
    
            // Get the shop's email from the Firestore database
            const shopDocRef = doc(db, 'shops', shop);  // 'shops' is the collection name and 'shop' is the shop identifier
            const shopDocSnap = await getDoc(shopDocRef);
    
            if (shopDocSnap.exists()) {
                const shopData = shopDocSnap.data();
                const shopEmail = shopData.emailAddress;  // Assuming the email is stored as 'email' in the shop document
    
                // Check if the logged-in user's email matches the shop's email
                if (user.email !== shopEmail) {
                    // If the emails do not match, show an error
                    setError('Invalid email or password. Please try again.');
                    return;
                }
    
                // Redirect to the dashboard after successful login and email match
                navigate(`/dashboard?shop=${encodeURIComponent(shop)}`);
            } else {
                setError('Shop not found.');
            }
        } catch (err) {
            console.error('Error signing in:', err);
            setError('Invalid email or password. Please try again.');
        }
    };
    

    return (
        <div style={styles.container}>
            <h1 style={styles.heading}>Login</h1>
            <form onSubmit={handleSubmit} style={styles.form}>
                <label style={styles.label}>
                    Email Address:
                    <input
                        type="email"
                        value={emailAddress}
                        onChange={(e) => setEmailAddress(e.target.value)}
                        required
                        style={styles.input}
                    />
                </label>
                <label style={styles.label}>
                    Password:
                    <input
                        type="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        required
                        style={styles.input}
                    />
                </label>
                <button type="submit" style={styles.button}>Login</button>
                {error && <p style={styles.error}>{error}</p>}

                <p>
                Don't have an Account? <Link to={`/signup?shop=${encodeURIComponent(shop)}`}>Signup</Link>
          </p>
          {/* {error && <span>Incorrect email or password.</span>} */}
          <p>
          <Link to='/login/forgetPassword'>
            Forgot Password?
          </Link>
        </p>
            </form>
        </div>
    );
};

const styles = {
    container: {
        maxWidth: '500px',
        margin: '50px auto',
        padding: '20px',
        border: '1px solid #ccc',
        borderRadius: '10px',
        boxShadow: '0 0 10px rgba(0,0,0,0.1)',
    },
    heading: {
        textAlign: 'center',
        marginBottom: '20px',
        color: '#000',
    },
    form: {
        display: 'flex',
        flexDirection: 'column',
    },
    label: {
        marginBottom: '15px',
        fontSize: '1em',
    },
    input: {
        width: '100%',
        padding: '10px',
        marginTop: '5px',
        borderRadius: '5px',
        border: '1px solid #ccc',
        fontSize: '1em',
    },
    button: {
        padding: '10px',
        borderRadius: '5px',
        border: 'none',
        backgroundColor: '#000',
        color: 'white',
        fontSize: '1em',
        cursor: 'pointer',
    },
    error: {
        color: 'red',
        marginTop: '10px',
        textAlign: 'center',
    },
};

export default Login;



