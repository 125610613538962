import React from 'react';
import Header from '../components/Header';
import Sidebar from '../components/Sidebar';
import '../style/TermsConditions.css'; // Ensure to import the CSS file

const TermsConditions = () => {
  return (
    <div className="products-page">
      <Header />
      <div className="products-container">
        <Sidebar />
        <div className="main-content">
          <h1>Terms and Conditions</h1>
          <p><strong>Effective Date:</strong> November 2, 2024</p>

          <h2>1. Introduction</h2>
          <p>
            Welcome to Unitee (“we,” “our,” or “us”). These Terms and Conditions govern your access to and use of our print-on-demand platform. By using our services, you agree to comply with and be bound by these terms.
          </p>

          <h2>2. User Accounts</h2>
          <p>
            Users are required to create an account to access certain features of our platform. You are responsible for maintaining the confidentiality of your account information, including your password, and for all activities that occur under your account. You agree to provide accurate, current, and complete information during the registration process and to update such information to keep it accurate, current, and complete.
          </p>

          <h2>3. Payment Terms</h2>
          <p>
            We accept various payment methods for our services. By providing payment information, you represent and warrant that you are authorized to use the payment method provided. All fees and charges are non-refundable unless otherwise stated.
          </p>

          <h2>4. Order Fulfillment</h2>
          <p>
            Upon placing an order, you will receive confirmation, and we will process your order according to the specifications provided. Once the order has been confirmed, it cannot be cancelled.
          </p>

          <h2>5. Copyright and Intellectual Property</h2>
          <p>
            Our platform allows users to upload and use their own content, such as designs, logos, images, and other potentially copyrightable material. By uploading content to our platform, you affirm that you own the rights to this material or have obtained permission to use it. You permit us to use the designs solely for the purpose of processing orders and fulfilling your requests. We are not liable for any copyright or intellectual property infringements arising from the use of such content. You are solely responsible for ensuring that all content complies with copyright, trademark, and other applicable intellectual property laws.
          </p>

          <h2>6. Limitation of Liability</h2>
          <p>
            To the fullest extent permitted by law, Unitee and its affiliates shall not be liable for any direct, indirect, incidental, or consequential damages resulting from your use of or inability to use our platform or services. This includes, but is not limited to, damages for loss of profits, goodwill, use, data, or other intangible losses.
          </p>

          <h2>7. Changes to the Terms</h2>
          <p>
            We reserve the right to modify these Terms and Conditions at any time. Any changes will be effective immediately upon posting the revised terms on our platform. Your continued use of the services after any such changes constitutes your acceptance of the new Terms and Conditions.
          </p>

          <h2>8. Contact Information</h2>
          <p>
            If you have any questions or concerns regarding these Terms and Conditions, please contact us at:
          </p>
          <p>
            <strong>Unitee Studios Private Limited</strong><br />
            Email: <a href="mailto:contact@uniteeculture.com">contact@uniteeculture.com</a><br />
            Phone: +91 8104038187<br />
            302 Red Rose, Mumbai 400052
          </p>
        </div>
      </div>
    </div>
  );
};

export default TermsConditions;
