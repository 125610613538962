// import React, { useState, useEffect, useRef } from 'react';
// import { db, Storage } from '../firebase';
// import { ref, uploadBytes, getDownloadURL, uploadBytesResumable } from 'firebase/storage';
// import { setDoc, doc, collection } from 'firebase/firestore';
// import { useLocation, useNavigate } from 'react-router-dom';
// import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
// import 'react-circular-progressbar/dist/styles.css';
// import '../style/DesignUpload.css';
// import { ToastContainer, toast } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';
// import { Canvas, Rect, Image as FabricImage,  } from 'fabric';
// import axios from 'axios';
// import Sidebar from '../components/Sidebar';
// import Header from '../components/Header';
// import Modal from '../components/Modal';
// import { fabric } from 'fabric';


// const DesignUpload = () => {
//   const location = useLocation();
//   // const { product } = location.state || {};
//   const { product } = location.state || {};
//   const frontCanvasRef = useRef(null);
//   const backCanvasRef = useRef(null);
//   const [frontCanvas, setFrontCanvas] = useState(null);
//   const [backCanvas, setBackCanvas] = useState(null);
//   const [designImages, setDesignImages] = useState({ frontDesignImage: null, backDesignImage: null });
//   const [view, setView] = useState(0); // 0 for front, 1 for back
//   const [designName, setDesignName] = useState('');
//   const [description, setDescription] = useState('');
//   const [designFileNames, setDesignFileNames] = useState({ frontDesignImage: '', backDesignImage: '' });
//   const [frontDesignSize, setFrontDesignSize] = useState({ width: 0, height: 0 });
//   const [backDesignSize, setBackDesignSize] = useState({ width: 0, height: 0 });
//   const [frontDesignCost, setFrontDesignCost] = useState(0);
//   const [backDesignCost, setBackDesignCost] = useState(0);
//   const [sellingPrice, setSellingPrice] = useState('');
//   const [totalPrice, setTotalPrice] = useState(0);
//   const [uploadProgress, setUploadProgress] = useState(0);
//   const [selectedMethod, setSelectedMethod] = useState('');

//   const productName = product.name; // or whatever key holds the product name
//   const productGSM = product.GSM;
//   const baseCost= product.BASE_COST; // similarly for GSM
//   const [isLoading, setIsLoading] = useState(false);
//   const [showModal, setShowModal] = useState(false);
//   const [isSubmitting, setIsSubmitting] = useState(false); 
//   const queryParams = new URLSearchParams(location.search);
//   const shop = queryParams.get('shop'); 

//   const navigate = useNavigate();

//   // useEffect(() => {
//   //   const initCanvas = (ref) => {
//   //     return new fabric.Canvas(ref.current, {
//   //       width: 440,
//   //       height: 540,
//   //       backgroundColor: "#fff",
//   //       // allowTouchScrolling: true ,
//   //     });
//   //   };

//   //   const frontFabricCanvas = initCanvas(frontCanvasRef);
//   //   const backFabricCanvas = initCanvas(backCanvasRef);

//   //   setFrontCanvas(frontFabricCanvas);
//   //   setBackCanvas(backFabricCanvas);

//   //   return () => {
//   //     frontFabricCanvas.dispose();
//   //     backFabricCanvas.dispose();
//   //   };
//   // }, []);

//   // useEffect(() => {
//   //   const loadMockupAndTexture = (canvas, viewIndex) => {
//   //     if (product) {
//   //       fabric.Image.fromURL(product.mockups[viewIndex], (img) => {
//   //         canvas.setBackgroundImage(img, canvas.renderAll.bind(canvas), {
//   //           scaleX: canvas.width / img.width,
//   //           scaleY: canvas.height / img.height,
//   //         });

//   //         const clippingRects =
//   //           viewIndex === 0
//   //             ? [
//   //                 new fabric.Rect({
//   //                   left: 115,
//   //                   top: 130,
//   //                   width: 210,
//   //                   height: 300,
//   //                   selectable: false,
//   //                   evented: false,
//   //                   stroke: "red",
//   //                   strokeWidth: 1,
//   //                   strokeDashArray: [4, 4],
//   //                   fill: "rgba(0,0,0,0)",
//   //                   id: "clippingRect1",
//   //                   absolutePositioned: true,
//   //                 }),
//   //                 new fabric.Rect({
//   //                   left: 200,
//   //                   top: 325,
//   //                   width: 100,
//   //                   height: 50,
//   //                   selectable: false,
//   //                   evented: false,
//   //                   strokeWidth: 1,
//   //                   strokeDashArray: [4, 4],
//   //                   fill: "rgba(0,0,0,0)",
//   //                   id: "clippingRect2",
//   //                   absolutePositioned: true,
//   //                 }),
//   //               ]
//   //             : [
//   //                 new fabric.Rect({
//   //                   left: 115,
//   //                   top: 130,
//   //                   width: 210,
//   //                   height: 300,
//   //                   selectable: false,
//   //                   evented: false,
//   //                   stroke: "red",
//   //                   strokeWidth: 1,
//   //                   strokeDashArray: [4, 4],
//   //                   fill: "rgba(0,0,0,0)",
//   //                   id: "clippingRectBack",
//   //                   absolutePositioned: true,
//   //                 }),
//   //               ];

//   //         clippingRects.forEach((rect) => canvas.add(rect));
//   //         canvas.clipPaths = clippingRects;

//   //         canvas.renderAll();
//   //       });

//   //       fabric.Image.fromURL(product.texture, (img) => {
//   //         canvas.setOverlayImage(img, canvas.renderAll.bind(canvas), {
//   //           scaleX: canvas.width / img.width,
//   //           scaleY: canvas.height / img.height,
//   //         });
//   //       });
//   //     }
//   //   };

//   //   if (frontCanvas) {
//   //     loadMockupAndTexture(frontCanvas, 0);
//   //   }
//   //   if (backCanvas) {
//   //     loadMockupAndTexture(backCanvas, 1);
//   //   }
//   // }, [product, frontCanvas, backCanvas]);

//   // const handleFileUpload = (e) => {
//   //   const file = e.target.files[0];
//   //   const activeCanvas = view === 0 ? frontCanvas : backCanvas;

//   //   if (!file || !file.type.startsWith("image/png")) {
//   //     toast.error("Please upload a PNG file.");
//   //     return;
//   //   }

//   //   const reader = new FileReader();
//   //   reader.onload = function (event) {
//   //     const img = new Image();
//   //     img.src = event.target.result;

//   //     img.onload = function () {
//   //       const dpi = Math.round(img.width / (activeCanvas.width / 25.4));
//   //       if (dpi < 150) {
//   //         toast.error("Print quality bad, high resolution art required.");
//   //         return;
//   //       } else {
//   //         toast.success("Print quality good.");
//   //       }

//   //       setDesignFileNames((prev) => ({
//   //         ...prev,
//   //         [view === 0 ? "frontDesignImage" : "backDesignImage"]: file.name
//   //       }));

//   //       setDesignImages((prev) => ({
//   //         ...prev,
//   //         [view === 0 ? "frontDesignImage" : "backDesignImage"]: file,
//   //       }));

//   //       const fabricImg = new fabric.Image(img);

//   //       if (view === 0) {
//   //         const scaleFactor = Math.min(210 / fabricImg.width, 300 / fabricImg.height);
//   //         fabricImg.scale(scaleFactor);
//   //         fabricImg.set({
//   //           left: 115 + (210 - fabricImg.width * scaleFactor) / 2,
//   //           top: 130 + (300 - fabricImg.height * scaleFactor) / 2,
//   //           clipPath: new fabric.Rect({
//   //             left: 115,
//   //             top: 130,
//   //             width: 210,
//   //             height: 300,
//   //             absolutePositioned: true,
//   //           }),
//   //         });
//   //       } else {
//   //         const scaleFactor = Math.min(210 / fabricImg.width, 300 / fabricImg.height);
//   //         fabricImg.scale(scaleFactor);
//   //         fabricImg.set({
//   //           left: 115 + (210 - fabricImg.width * scaleFactor) / 2,
//   //           top: 130 + (300 - fabricImg.height * scaleFactor) / 2,
//   //           clipPath: new fabric.Rect({
//   //             left: 115,
//   //             top: 130,
//   //             width: 210,
//   //             height: 300,
//   //             absolutePositioned: true,
//   //           }),
//   //         });
//   //       }

//   //       activeCanvas.add(fabricImg);
//   //       activeCanvas.renderAll();
//   //     };
//   //   };

//   //   reader.readAsDataURL(file);
//   //   // setDrawerOpen(false);
//   // };



//   useEffect(() => {
//     const initCanvas = (ref) => {
//       return new Canvas(ref.current, {
//         width: 440,
//         height: 540,
//         backgroundColor: "#fff",
//       });
//     };

//     const frontFabricCanvas = initCanvas(frontCanvasRef);
//     const backFabricCanvas = initCanvas(backCanvasRef);

//     setFrontCanvas(frontFabricCanvas);
//     setBackCanvas(backFabricCanvas);

//     return () => {
//       frontFabricCanvas.dispose();
//       backFabricCanvas.dispose();
//     };
//   }, []);

//   useEffect(() => {
//     const loadMockupAndTexture = async (canvas, viewIndex) => {
//       if (product) {
//         try {
//           const img = await FabricImage.fromURL(product.mockups[viewIndex]);
//           canvas.set("backgroundImage", img);
//           img.scaleToWidth(canvas.width);
//           img.scaleToHeight(canvas.height);
//           canvas.renderAll();

//           const clippingRects = viewIndex === 0 ? [
//             new Rect({ left: 108, top: 135, width: 210, height: 300, selectable: false, evented: false, stroke: "red", strokeWidth: 1, strokeDashArray: [4, 4], fill: "rgba(0,0,0,0)", id: "clippingRect1" }),
//             new Rect({ left: 200, top: 325, width: 100, height: 50, selectable: false, evented: false, strokeWidth: 1, strokeDashArray: [4, 4], fill: "rgba(0,0,0,0)", id: "clippingRect2" }),
//           ] : [
//             new Rect({ left: 115, top: 130, width: 210, height: 300, selectable: false, evented: false, stroke: "blue", strokeWidth: 1, strokeDashArray: [4, 4], fill: "rgba(0,0,0,0)", id: "clippingRectBack" }),
//           ];

//           clippingRects.forEach((rect) => canvas.add(rect));
//           canvas.clipPaths = clippingRects;
//           canvas.renderAll();
//         } catch (error) {
//           console.error("Error loading mockup image:", error);
//           // toast.error("Failed to load mockup image.");
//         }
//       }
//     };

//     if (frontCanvas) loadMockupAndTexture(frontCanvas, 0);
//     if (backCanvas) loadMockupAndTexture(backCanvas, 1);
//   }, [product, frontCanvas, backCanvas]);

//   useEffect(() => {
//     const totalCost = frontDesignCost + backDesignCost;
//     const calculatedTotalPrice = product.BASE_COST + totalCost; // Adjust based on your requirement
//     setTotalPrice(calculatedTotalPrice);
//   }, [frontDesignCost, backDesignCost, product.BASE_COST]);

//   const handleSellingPriceChange = (e) => {
//     setSellingPrice(e.target.value);
//   };

//   const updateCostAndSize = (widthUnits, heightUnits, isFront) => {

//     const area = widthUnits * heightUnits; 
//     let newCost = 0;

//     if (area <= 25) {  // Equivalent to 5x5
//       newCost = product.costStructure.logo || 0;
//     } else if (area <= 97.11) {  // Equivalent to 8.3x11.7 (A4)
//       newCost = product.costStructure.A4 || 0;
//     } else if (area <= 224) {  // Equivalent to 14x16 (A3)
//       newCost = product.costStructure.A3 || 0;
//     } else if (area <= 294.5) {  // Equivalent to 15.5x19 (Full)
//       newCost = product.costStructure.full || 0;
//     } else {
//       // You can add a condition for any design larger than 15.5x19
//       newCost = product.costStructure.extraLarge || 0; 
//     }


//     if (isFront) {
//       setFrontDesignSize({ width: widthUnits, height: heightUnits });
//       setFrontDesignCost(newCost);
//     } else {
//       setBackDesignSize({ width: widthUnits, height: heightUnits });
//       setBackDesignCost(newCost);
//     }
//   };



// //   const uploadDesignImage = async (file, designName, designType, setUploadProgress) => {
// //     // Generate a unique filename using the design name and current timestamp
// //     const timestamp = Date.now();
// //     const fileRef = ref(Storage, `designs/${designName}-${designType}-${timestamp}.png`); // Unique path

// //     // Create an upload task with resumable upload to monitor progress
// //     const uploadTask = uploadBytesResumable(fileRef, file);

// //     // Monitor upload progress
// //     uploadTask.on('state_changed', 
// //       (snapshot) => {
// //         // Get upload progress
// //         const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
// //         setUploadProgress(progress); // Update progress for the circular progress bar
// //       }, 
// //       (error) => {
// //         // Handle errors
// //         console.error("Upload failed:", error);
// //         // toast.error("Error uploading image.");
// //       },
// //       async () => {
// //         // Upload completed successfully
// //         const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
// //         return downloadURL;
// //       }
// //     );

// //     // Wait for the upload to complete and get the download URL
// //     const snapshot = await uploadTask;
// //     const url = await getDownloadURL(snapshot.ref);
// //     return url;
// // };


//   const handleFileUpload = (e) => {
//     const file = e.target.files[0];
//     const activeCanvas = view === 0 ? frontCanvas : backCanvas;
  
//     if (!file || !file.type.startsWith("image/png")) {
//       // toast.error("Please upload a PNG file.");
//       return;
//     }
  
  
//     const reader = new FileReader();
//     reader.onload = async (event) => {
//       const img = new Image();
//       img.src = event.target.result;
  
//       img.onload = async () => {
//         const dpi = Math.round(img.width / (activeCanvas.width / 25.4));
//         if (dpi < 100) {
//           toast.error("High resolution art required.");
//           return;
//         } else {
//           toast.success("Print quality good.");
//           setIsLoading(true);
//         }
  
//         setDesignFileNames((prev) => ({
//           ...prev,
//           [view === 0 ? "frontDesignImage" : "backDesignImage"]: file.name,
//         }));
  
//         setDesignImages((prev) => ({
//           ...prev,
//           [view === 0 ? "frontDesignImage" : "backDesignImage"]: file,
//         }));
  
//         try {
//           // const designImageUrl = await uploadDesignImage(file, designName, view === 0 ? 'front' : 'back', (progress) => {
//           //   // Update progress during upload
//           //   setUploadProgress(progress);
//           // });
  
//           // setDesignImages((prev) => ({
//           //   ...prev,
//           //   [view === 0 ? "frontDesignImageUrl" : "backDesignImageUrl"]: designImageUrl,
//           // }));
//           const fabricImg = await FabricImage.fromURL(img.src);
  
//           if (!fabricImg.width || !fabricImg.height) {
//             throw new Error("Image dimensions are not available.");
//           }
  
//           // Define different scale factors for front and back
//           const frontScaleFactor = Math.min(210 / fabricImg.width, 300 / fabricImg.height);
//           const backScaleFactor = Math.min(210 / fabricImg.width, 300 / fabricImg.height); // Example values for the back
  
//           const scaleFactor = view === 0 ? frontScaleFactor : backScaleFactor;
  
//           fabricImg.scale(scaleFactor);
  
//           // Define different left, top, and clipPath for front and back
//           const frontLeft = 108;
//           const frontTop = 135;
//           const frontClipPath = new Rect({
//             left: frontLeft,
//             top: frontTop,
//             width: 210,
//             height: 300,
//             absolutePositioned: true,
//           });
  
//           const backLeft = 115; // Example position for the back
//           const backTop = 130;  // Example position for the back
//           const backClipPath = new Rect({
//             left: backLeft,
//             top: backTop,
//             width: 210, // Example width for the back
//             height: 300, // Example height for the back
//             absolutePositioned: true,
//           });
  
//           fabricImg.set({
//             left: view === 0 ? frontLeft + (210 - fabricImg.width * scaleFactor) / 2 : backLeft + (210 - fabricImg.width * scaleFactor) / 2,
//             top: view === 0 ? frontTop + (300 - fabricImg.height * scaleFactor) / 2 : backTop + (300 - fabricImg.height * scaleFactor) / 2,
//             clipPath: view === 0 ? frontClipPath : backClipPath,
//           });
  
//           const initialWidthUnits = (fabricImg.width * scaleFactor) / (210 / 15.5);
//           const initialHeightUnits = (fabricImg.height * scaleFactor) / (300 / 19);
//           updateCostAndSize(initialWidthUnits, initialHeightUnits, view === 0);
  
//           activeCanvas.add(fabricImg);
//           activeCanvas.renderAll();
  
//           // Restrict resizing to the defined rectangle
//           // fabricImg.on('scaling', () => {
//           //   const object = fabricImg;
//           //   const bounds = {
//           //     left: view === 0 ? frontLeft : backLeft,
//           //     top: view === 0 ? frontTop : backTop,
//           //     width: view === 0 ? 210 : 210,
//           //     height: view === 0 ? 300 : 300,
//           //   };
  
//           //   const newWidth = object.width * object.scaleX;
//           //   const newHeight = object.height * object.scaleY;
  
//           //   if (newWidth > bounds.width || newHeight > bounds.height) {
//           //     object.scaleX = bounds.width / object.width;
//           //     object.scaleY = bounds.height / object.height;
//           //   }
  
//           //   const newLeft = object.left + (object.width * (object.scaleX - 1)) / 2;
//           //   const newTop = object.top + (object.height * (object.scaleY - 1)) / 2;
  
//           //   // Prevent moving out of bounds
//           //   if (newLeft < bounds.left) {
//           //     object.left = bounds.left;
//           //   } else if (newLeft + newWidth > bounds.left + bounds.width) {
//           //     object.left = bounds.left + bounds.width - newWidth;
//           //   } else {
//           //     object.left = newLeft;
//           //   }
  
//           //   if (newTop < bounds.top) {
//           //     object.top = bounds.top;
//           //   } else if (newTop + newHeight > bounds.top + bounds.height) {
//           //     object.top = bounds.top + bounds.height - newHeight;
//           //   } else {
//           //     object.top = newTop;
//           //   }
  
//           //   const adjustedWidthUnits = (object.width * object.scaleX) / (210 / 15.5);
//           //   const adjustedHeightUnits = (object.height * object.scaleY) / (300 / 19);
//           //   updateCostAndSize(adjustedWidthUnits, adjustedHeightUnits, view === 0);
//           // });

//           // fabricImg.on('scaling', () => {
//           //   const object = fabricImg;
//           //   const bounds = {
//           //     left: view === 0 ? frontLeft : backLeft,
//           //     top: view === 0 ? frontTop : backTop,
//           //     width: view === 0 ? 210 : 210,
//           //     height: view === 0 ? 300 : 300,
//           //   };
          
//           //   const aspectRatio = object.width / object.height;
//           //   let newWidth = object.width * object.scaleX;
//           //   let newHeight = object.height * object.scaleY;
          
//           //   // Maintain aspect ratio when resizing
//           //   if (newWidth > bounds.width || newHeight > bounds.height) {
//           //     if (newWidth > newHeight) {
//           //       newWidth = bounds.width;
//           //       newHeight = newWidth / aspectRatio; // Calculate new height based on aspect ratio
//           //     } else {
//           //       newHeight = bounds.height;
//           //       newWidth = newHeight * aspectRatio; // Calculate new width based on aspect ratio
//           //     }
          
//           //     object.scaleX = newWidth / object.width;
//           //     object.scaleY = newHeight / object.height;
//           //   }
          
//           //   const newLeft = object.left + (object.width * (object.scaleX - 1)) / 2;
//           //   const newTop = object.top + (object.height * (object.scaleY - 1)) / 2;
          
//           //   // Prevent moving out of bounds
//           //   if (newLeft < bounds.left) {
//           //     object.left = bounds.left;
//           //   } else if (newLeft + newWidth > bounds.left + bounds.width) {
//           //     object.left = bounds.left + bounds.width - newWidth;
//           //   } else {
//           //     object.left = newLeft;
//           //   }
          
//           //   if (newTop < bounds.top) {
//           //     object.top = bounds.top;
//           //   } else if (newTop + newHeight > bounds.top + bounds.height) {
//           //     object.top = bounds.top + bounds.height - newHeight;
//           //   } else {
//           //     object.top = newTop;
//           //   }
          
//           //   const adjustedWidthUnits = (object.width * object.scaleX) / (210 / 15.5);
//           //   const adjustedHeightUnits = (object.height * object.scaleY) / (300 / 19);
//           //   updateCostAndSize(adjustedWidthUnits, adjustedHeightUnits, view === 0);
//           // });
          

//           // fabricImg.on('scaling', () => {
//           //   const object = fabricImg;
//           //   const bounds = {
//           //     left: view === 0 ? frontLeft : backLeft,
//           //     top: view === 0 ? frontTop : backTop,
//           //     width: 210, // Assuming these are constants for your design area
//           //     height: 300,
//           //   };
          
//           //   const aspectRatio = object.width / object.height;
//           //   let newWidth = object.width * object.scaleX;
//           //   let newHeight = object.height * object.scaleY;
          
//           //   // Maintain aspect ratio when resizing
//           //   if (newWidth > bounds.width || newHeight > bounds.height) {
//           //     if (newWidth > newHeight) {
//           //       newWidth = bounds.width;
//           //       newHeight = newWidth / aspectRatio; // Calculate new height based on aspect ratio
//           //     } else {
//           //       newHeight = bounds.height;
//           //       newWidth = newHeight * aspectRatio; // Calculate new width based on aspect ratio
//           //     }
          
//           //     object.scaleX = newWidth / object.width;
//           //     object.scaleY = newHeight / object.height;
//           //   }
          
//           //   // Ensure the object doesn't move outside the bounds
//           //   const newLeft = object.left + (object.width * (object.scaleX - 1)) / 2;
//           //   const newTop = object.top + (object.height * (object.scaleY - 1)) / 2;
          
//           //   // Prevent moving out of bounds
//           //   if (newLeft < bounds.left) {
//           //     object.left = bounds.left;
//           //   } else if (newLeft + newWidth > bounds.left + bounds.width) {
//           //     object.left = bounds.left + bounds.width - newWidth;
//           //   } else {
//           //     object.left = newLeft;
//           //   }
          
//           //   if (newTop < bounds.top) {
//           //     object.top = bounds.top;
//           //   } else if (newTop + newHeight > bounds.top + bounds.height) {
//           //     object.top = bounds.top + bounds.height - newHeight;
//           //   } else {
//           //     object.top = newTop;
//           //   }
          
//           //   // Optionally update the cost/size based on the adjusted dimensions
//           //   const adjustedWidthUnits = (object.width * object.scaleX) / (210 / 15.5);
//           //   const adjustedHeightUnits = (object.height * object.scaleY) / (300 / 19);
//           //   updateCostAndSize(adjustedWidthUnits, adjustedHeightUnits, view === 0);
//           // });



          
//           // fabricImg.on('scaling', () => {
//           //   const object = fabricImg;
//           //   const bounds = {
//           //     left: view === 0 ? frontLeft : backLeft,
//           //     top: view === 0 ? frontTop : backTop,
//           //     width: 210, // Assuming these are constants for your design area
//           //     height: 300,
//           //   };
          
//           //   const aspectRatio = object.width / object.height;
//           //   let newWidth = object.width * object.scaleX;
//           //   let newHeight = object.height * object.scaleY;
          
//           //   // Maintain aspect ratio when resizing
//           //   if (newWidth > bounds.width || newHeight > bounds.height) {
//           //     if (newWidth > newHeight) {
//           //       newWidth = bounds.width;
//           //       newHeight = newWidth / aspectRatio; // Calculate new height based on aspect ratio
//           //     } else {
//           //       newHeight = bounds.height;
//           //       newWidth = newHeight * aspectRatio; // Calculate new width based on aspect ratio
//           //     }
          
//           //     object.scaleX = newWidth / object.width;
//           //     object.scaleY = newHeight / object.height;
//           //   }
          
//           //   // Ensure the object doesn't move outside the bounds
//           //   // Prevent moving out of bounds but keep it in place
//           //   const newLeft = object.left + (object.width * (object.scaleX - 1)) / 2;
//           //   const newTop = object.top + (object.height * (object.scaleY - 1)) / 2;
          
//           //   // Prevent moving out of bounds
//           //   if (newLeft < bounds.left) {
//           //     object.left = bounds.left;
//           //   } else if (newLeft + newWidth > bounds.left + bounds.width) {
//           //     object.left = bounds.left + bounds.width - newWidth;
//           //   } else {
//           //     object.left = newLeft;
//           //   }
          
//           //   if (newTop < bounds.top) {
//           //     object.top = bounds.top;
//           //   } else if (newTop + newHeight > bounds.top + bounds.height) {
//           //     object.top = bounds.top + bounds.height - newHeight;
//           //   } else {
//           //     object.top = newTop;
//           //   }
          
//           //   // Optionally update the cost/size based on the adjusted dimensions
//           //   const adjustedWidthUnits = (object.width * object.scaleX) / (210 / 15.5);
//           //   const adjustedHeightUnits = (object.height * object.scaleY) / (300 / 19);
//           //   updateCostAndSize(adjustedWidthUnits, adjustedHeightUnits, view === 0);
//           // });

//           // fabricImg.on('scaling', () => {
//           //   const object = fabricImg;
//           //   // const bounds = {
//           //   //   left: view === 0 ? frontLeft : backLeft,
//           //   //   top: view === 0 ? frontTop : backTop,
//           //   //   width: 210, // Assuming these are constants for your design area
//           //   //   height: 300,
//           //   // };
          
//           //   const aspectRatio = object.width / object.height;
//           //   let newWidth = object.width * object.scaleX;
//           //   let newHeight = object.height * object.scaleY;
          
//           //   // Maintain aspect ratio when resizing
//           //   // if (newWidth > bounds.width || newHeight > bounds.height) {
//           //   //   if (newWidth > newHeight) {
//           //   //     newWidth = bounds.width;
//           //   //     newHeight = newWidth / aspectRatio; // Calculate new height based on aspect ratio
//           //   //   } else {
//           //   //     newHeight = bounds.height;
//           //   //     newWidth = newHeight * aspectRatio; // Calculate new width based on aspect ratio
//           //   //   }
          
//           //   //   object.scaleX = newWidth / object.width;
//           //   //   object.scaleY = newHeight / object.height;
//           //   // }
          
//           //   // After scaling, adjust the position to ensure the object stays within bounds
//           //   const newLeft = object.left + (object.width * (object.scaleX - 1)) / 2;
//           //   const newTop = object.top + (object.height * (object.scaleY - 1)) / 2;
          
//           //   // Prevent moving out of bounds by adjusting the position
//           //   // if (newLeft < bounds.left) {
//           //   //   object.left = bounds.left;
//           //   // } else if (newLeft + newWidth > bounds.left + bounds.width) {
//           //   //   object.left = bounds.left + bounds.width - newWidth;
//           //   // } else {
//           //   //   object.left = newLeft;
//           //   // }
          
//           //   // if (newTop < bounds.top) {
//           //   //   object.top = bounds.top;
//           //   // } else if (newTop + newHeight > bounds.top + bounds.height) {
//           //   //   object.top = bounds.top + bounds.height - newHeight;
//           //   // } else {
//           //   //   object.top = newTop;
//           //   // }
          
//           //   // Optionally update the cost/size based on the adjusted dimensions
//           //   const adjustedWidthUnits = (object.width * object.scaleX) / (210 / 15.5);
//           //   const adjustedHeightUnits = (object.height * object.scaleY) / (300 / 19);
//           //   updateCostAndSize(adjustedWidthUnits, adjustedHeightUnits, view === 0);
//           // });
          
//           fabricImg.on('scaling', () => {
//             const object = fabricImg;
            
//             // Define the boundary area (this can be dynamic based on your design area)
//             const bounds = {
//                 left: view === 0 ? frontLeft : backLeft,
//                 top: view === 0 ? frontTop : backTop,
//                 width: 210,  // Width of the boundary
//                 height: 300, // Height of the boundary
//             };
        
//             // Calculate the aspect ratio of the image
//             const aspectRatio = object.width / object.height;
//             let newWidth = object.width * object.scaleX;
//             let newHeight = object.height * object.scaleY;
        
//             // Prevent the image from going outside the bounds (scale the image to fit within the bounds)
//             if (newWidth > bounds.width) {
//                 newWidth = bounds.width;
//                 newHeight = newWidth / aspectRatio;
//                 object.scaleX = newWidth / object.width;
//                 object.scaleY = newHeight / object.height;
//             }
        
//             if (newHeight > bounds.height) {
//                 newHeight = bounds.height;
//                 newWidth = newHeight * aspectRatio;
//                 object.scaleX = newWidth / object.width;
//                 object.scaleY = newHeight / object.height;
//             }
        
//             // Calculate the new left and top positions after scaling
//             const newLeft = object.left + (object.width * (object.scaleX - 1)) / 2;
//             const newTop = object.top + (object.height * (object.scaleY - 1)) / 2;
        
//             // Prevent the object from going out of bounds horizontally (left)
//             if (newLeft < bounds.left) {
//                 object.left = bounds.left;
//             } else if (newLeft + newWidth > bounds.left + bounds.width) {
//                 object.left = bounds.left + bounds.width - newWidth;
//             } else {
//                 object.left = newLeft;
//             }
        
//             // Prevent the object from going out of bounds vertically (top)
//             // if (newTop < bounds.top) {
//             //     object.top = bounds.top;
//             // } else if (newTop + newHeight > bounds.top + bounds.height) {
//             //     // When scaling exceeds bottom boundary, adjust top smoothly
//             //     const excessBottom = newTop + newHeight - (bounds.top + bounds.height);
//             //     object.top = newTop - excessBottom;
        
//             //     // Ensure the top doesn't go above the top boundary if the object scales upward
//             //     if (object.top < bounds.top) {
//             //         object.top = bounds.top;
//             //     }
//             // } else {
//             //     object.top = newTop;
//             // }
        
//             // Optional: Update the cost/size based on the adjusted dimensions
//             const adjustedWidthUnits = (object.width * object.scaleX) / (210 / 15.5);
//             const adjustedHeightUnits = (object.height * object.scaleY) / (300 / 19);
//             updateCostAndSize(adjustedWidthUnits, adjustedHeightUnits, view === 0);
//         });        
          
  
//           fabricImg.on('scaled', () => {
//             const object = fabricImg;
//             if (object) {
//               object.set({
//                 scaleX: 1,
//                 scaleY: 1,
//               });
//             }
//           });
  
//         } catch (error) {
//           console.error("Error with Fabric Image: ", error);
//           // toast.error("Error loading image. Please try again.");
//         } finally {
//           setIsLoading(false); // Stop loading
//         }
//       };
  
//       img.onerror = () => {
//         // toast.error("Failed to load image. Please try again.");
//         setIsLoading(false);
//       };
//     };
  
//     reader.readAsDataURL(file);
//   };




//   const handleSubmit = async (status) => {
//     setIsSubmitting(true);

//     // Ensure at least one design has been uploaded
//     if (!designImages.frontDesignImage && !designImages.backDesignImage) {
//       // toast.error("Please upload at least one design image (front or back).");
//       return;
//     }


//     const frontImageUrl = designImages.frontDesignImageUrl || null;
//     const backImageUrl = designImages.backDesignImageUrl || null;

//     // const frontImageUrl = await saveFinalDesign('front');  
//     // const backImageUrl = await saveFinalDesign('back'); 

//     if (!frontImageUrl && !backImageUrl) {
//       // toast.error("Failed to upload images. Please try again.");
//       return;
//     }

//     // setIsSubmitting(true);

//     try {
//       // Function to convert Data URI to Blob
//       const dataURItoBlob = (dataURI) => {
//         const byteString = dataURI.split(',')[1];
//         const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
//         const ia = new Uint8Array(atob(byteString).split('').map(char => char.charCodeAt(0)));
//         return new Blob([ia], { type: mimeString });
//       };

//       // Save final canvas design to Firebase Storage
//       const saveCanvasToStorage = async (canvas, canvasName) => {
//         canvas.getObjects("rect").forEach((rect) => rect.set({ visible: false })); // Hide any visible rectangles
//         canvas.renderAll();
//         const canvasPNG = canvas.toDataURL({ format: 'png' }); // Save as PNG for quality
//         const canvasRef = ref(Storage, `mockups/${designName}-${canvasName}.png`);
//         await uploadBytes(canvasRef, dataURItoBlob(canvasPNG));
//         const url = await getDownloadURL(canvasRef);
//         return url;
//       };




      

//       // Generate SKUs for different sizes
//       const generateSKUs = () => {
//         const randomNum = Math.floor(Math.random() * 1000000);
//         const sizes = ['S', 'M', 'L', 'XL', 'XXL'];
//         return sizes.map(size => `u-${randomNum}-${size}`);
//       };
//       const skus = generateSKUs();

//       // Export the final combined design for front and back mockups
//       const frontMockupUrl = await saveCanvasToStorage(frontCanvas, 'front');
//       const backMockupUrl = await saveCanvasToStorage(backCanvas, 'back');

//       // Create design document in Firestore
//       const designRef = doc(collection(db, 'shops', shop, 'designs'));
//       await setDoc(designRef, {
//         designName,
//         description,
//         sellingPrice,
//         totalPrice,
//         frontDesign: frontImageUrl, // Use the URL from state
//         backDesign: backImageUrl,   // Use the URL from state
//         images: {
//           front: frontMockupUrl,
//           back: backMockupUrl,
//         },
//         costs: {
//           front: frontDesignCost,
//           back: backDesignCost,
//         },
//         Printsizes: {
//           front: frontDesignSize,
//           back: backDesignSize,
//         },
//         productName: product.name, // Use product data
//         productGSM: product.GSM,
//         SKUs: skus, // Use product GSM
//         baseCost: product.BASE_COST,
//         PrintCost: frontDesignCost + backDesignCost,
//         // printMethod: selectedMethod
//       });

//       // Create payload for the external API
//       const payload = {
//         productName: productName,
//         frontImage: frontMockupUrl,
//         backImage: backMockupUrl,
//         frontDesign: frontImageUrl, // Use the URL from state
//         backDesign: backImageUrl,
//         shop: shop,
//         designName: designName,
//         description: description,
//         sellingPrice: sellingPrice,
//         SKUs: skus,
//         cost: totalPrice,
//         PrintCost: frontDesignCost + backDesignCost,
//       };

//       // Send the data to your external API (to create a draft product)
//       await axios.post('https://ufb-1.onrender.com/api/create-active-product', {
//         ...payload,
//         status, 
//       });

//       // toast.success(
//       //   <>
//       //     <span>Design uploaded successfully! </span>
//       //     <a href={`/products?shop=${shop}`} style={{ color: 'blue', textDecoration: 'underline' }}>
//       //       Go to product page
//       //     </a>
//       //   </>,
//       // );

//       // Redirect after successful submission
//       toast.success('Design uploaded successfully!');
//       setShowModal(true);
//       // navigate(`/products?shop=${shop}`);
//     } catch (error) {
//       console.error("Error uploading design:", error);
//       toast.error("Failed to upload design.");
//     } finally {
//       setIsSubmitting(false);
//     }
// };

// const handleGoToProducts = () => {
//   // Navigate to the products page after clicking "Go"
//   navigate(`/products?shop=${shop}`);
// };

//   const handleRotate = () => {
//     const activeCanvas = view === 0 ? frontCanvas : backCanvas;
//     const activeObjects = activeCanvas.getActiveObjects();

//     activeObjects.forEach((obj) => {
//       obj.rotate(obj.angle + 90);
//       obj.setCoords();
//     });
//     activeCanvas.renderAll();
//   };

//   const handleDelete = () => {
//     const activeCanvas = view === 0 ? frontCanvas : backCanvas;
//     const activeObjects = activeCanvas.getActiveObjects();

//     activeObjects.forEach((obj) => {
//       activeCanvas.remove(obj);
//     });
//     activeCanvas.discardActiveObject();
//     activeCanvas.renderAll();
//   };

//   return (
//     <div className="one">
//        <ToastContainer />
//     <Header/>
//     <div className="design-upload-container">
//      <Sidebar />

     
//       <div className="form-wrapper">

//         {/* <h2>Design Upload</h2> */}



// {isLoading && (
//   <div className="loading-overlay">
//     <div className="loader-container">
//       <div className="progress-container">
//         {/* Circular progress bar */}
//         <CircularProgressbar
//           value={uploadProgress}
//           text={`${Math.round(uploadProgress)}%`} 
          
//           styles={buildStyles({
//            pathColor: '#FF3E4D', 
//             textColor: '#FF3E4D',
//             textSize: '30px',      // Adjust the text size
//             fontWeight: 'extra-bold',    // Make the text bold
//           })}
//         />
//       </div>
//     </div>
//   </div>
// )}

//         <form onSubmit={handleSubmit}>
//           <div className="form-group">
//           {/* <div className="print-method-container">
//   <h3>PRINT METHOD</h3>
//   <div className="print-method-buttons">
//     <button
//       className={`print-method-button ${selectedMethod === 'DTG-Brother' ? 'active' : ''}`}
//       onClick={() => setSelectedMethod('DTG-Brother')}
//     >
//       DTG <span className="print-method-label">BROTHER</span>
//     </button>
//     <button
//       className={`print-method-button ${selectedMethod === 'DTG-Epson' ? 'active' : ''}`}
//       onClick={() => setSelectedMethod('DTG-Epson')}
//     >
//       DTG <span className="print-method-label">EPSON</span>
//     </button>
//     <button
//       className={`print-method-button ${selectedMethod === 'DTF-Epson' ? 'active' : ''}`}
//       onClick={() => setSelectedMethod('DTF-Epson')}
//     >
//       DTF <span className="print-method-label">EPSON</span>
//     </button>
//   </div>
// </div> */}
//             <label>Upload Design Image:</label>
//             <input
//               type="file"
//               accept=".png"
//               onChange={handleFileUpload}
//               multiple
//               style={{ display: 'none' }} // Hide the default file input
//               id="designImage"
//             />
//             <label htmlFor="designImage" className="custom-file-upload">
//               + Add Image
//             </label>
//             {/* {designFileNames[view === 0 ? "frontDesignImage" : "backDesignImage"] && (
//               <p>Uploaded: {designFileNames[view === 0 ? "frontDesignImage" : "backDesignImage"]}</p>
//             )} */}

// {Object.keys(designFileNames).map((key) => {
//   if (designFileNames[key]) {
//     return (
//       <p key={key}>Uploaded: {designFileNames[key]}</p>
//     );
//   }
//   return null;
// })}
//           </div>
//           <div className="form-group">
//             <label htmlFor="designName">Design Name:</label>
//             <input
//               type="text"
//               id="designName"
//               value={designName}
//               onChange={(e) => setDesignName(e.target.value)}
//               placeholder="Design Name"
//               required
//             />
//           </div>
//           <div className="form-group">
//             <label htmlFor="description">Description:</label>
//             <textarea
//               id="description"
//               value={description}
//               onChange={(e) => setDescription(e.target.value)}
//               placeholder="Description"
//               required
//             />
//           </div>
//           <div className="design-details">
//             <p><label>GSM: {product.GSM}</label></p>
//             <p><label>Front Design Size: {frontDesignSize.height.toFixed(1)} x {frontDesignSize.width.toFixed(1)} in</label></p>
//             <p><label>Front Design Cost: ₹{frontDesignCost}</label></p>
//             <p><label>Back Design Size: {backDesignSize.height.toFixed(1)} x {backDesignSize.width.toFixed(1)} in</label></p>
//             <p><label>Back Design Cost: ₹{backDesignCost}</label></p>
//             {/* <p><label>Total Cost: ₹{frontDesignCost + backDesignCost}</label></p> */}
//           </div>
            
//           <div className="total-price">
//             <p><label>Total Cost: ₹{totalPrice} + Shipping </label></p>
//           </div>

//           <div className="form-group">
//             <label htmlFor="sellingPrice">Selling Price:</label>
//             <input
//               type="number"
//               id="sellingPrice"
//               value={sellingPrice}
//               onChange={handleSellingPriceChange}
//               placeholder="Enter Selling Price"
//               required
//             />
//           </div>

//           {/* Total Price Display */}
//           {/* <div className="total-price">
//             <p><label>Total Cost: ₹{totalPrice}</label></p>
//           </div> */}
//           {/* <button type="submit" className='desktop-submit'>Submit</button> */}

//           <button type="button" disabled={isSubmitting} onClick={() => handleSubmit('active')} className='desktop-submit'>
//   {isSubmitting ? 'Submitting...' : 'Make It Live!'}
// </button>
// <br/>
// <button type="button" disabled={isSubmitting} onClick={() => handleSubmit('draft')} className='desktop-draft'>
//   {isSubmitting ? 'Saving as Draft...' : 'Make Draft'}
// </button>
// <div className='skualert'>*Do not change the SKU of the Shopify product after creation.</div>
//         </form>

//         {showModal && (
//         <Modal>
//           <div className="modal-content">
//             <h2>Your products has been created successfully!</h2>
           
//             <button onClick={handleGoToProducts}>Go to products page!</button>
//           </div>
//         </Modal>
//       )}
//       </div>
//       <div className="mockup">
 

// <div className="controls">
//   <select onChange={(e) => setView(Number(e.target.value))} value={view} className="view-select">
//     <option value={0}>Front</option>
//     <option value={1}>Back</option>
//   </select>
//   <button onClick={handleRotate} className="control-button">Rotate</button>
//   <button onClick={handleDelete} className="control-button">Delete</button>
// </div>
//         <div style={{ display: view === 0 ? 'block' : 'none' }}>
//           <canvas ref={frontCanvasRef} />
//         </div>
//         <div style={{ display: view === 1 ? 'block' : 'none' }}>
//           <canvas ref={backCanvasRef} />
//         </div>
        
//       </div>
      
//     </div>
//     </div>
//   );
// };

// export default DesignUpload;


import React, { useState, useEffect, useRef } from 'react';
import { db, Storage } from '../firebase';
import { ref, uploadBytes, getDownloadURL, uploadBytesResumable } from 'firebase/storage';
import { setDoc, doc, collection,serverTimestamp } from 'firebase/firestore';
import { useLocation, useNavigate } from 'react-router-dom';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';

import 'react-circular-progressbar/dist/styles.css';
import '../style/DesignUpload.css';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Canvas, Rect, Image as FabricImage } from 'fabric';
import axios from 'axios';
import Sidebar from '../components/Sidebar';
import Header from '../components/Header';
import Modal from '../components/Modal';
import { FaCircle, FaRoute, FaSquareRootAlt, FaTimes, FaTrash, FaUndo } from 'react-icons/fa';


const DesignUpload = () => {
  const location = useLocation();
  // const { product } = location.state || {};
  const { product } = location.state || {};
  const frontCanvasRef = useRef(null);
  const backCanvasRef = useRef(null);
  const [frontCanvas, setFrontCanvas] = useState(null);
  const [backCanvas, setBackCanvas] = useState(null);
  const [designImages, setDesignImages] = useState({ frontDesignImage: null, backDesignImage: null });
  const [view, setView] = useState(0); // 0 for front, 1 for back
  const [designName, setDesignName] = useState('');
  const [description, setDescription] = useState('');
  const [designFileNames, setDesignFileNames] = useState({ frontDesignImage: '', backDesignImage: '' });
  const [frontDesignSize, setFrontDesignSize] = useState({ width: 0, height: 0 });
  const [backDesignSize, setBackDesignSize] = useState({ width: 0, height: 0 });
  const [frontDesignCost, setFrontDesignCost] = useState(0);
  const [backDesignCost, setBackDesignCost] = useState(0);
  const [sellingPrice, setSellingPrice] = useState('');
  const [totalPrice, setTotalPrice] = useState(0);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const productName = product.name; // or whatever key holds the product name
  const productGSM = product.GSM;
  const tax= product.tax;
  const baseCost= product.BASE_COST; // similarly for GSM
  const [isLoading, setIsLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false); 
  const queryParams = new URLSearchParams(location.search);
  const shop = queryParams.get('shop'); 

  const navigate = useNavigate();


  useEffect(() => {
    const initCanvas = (ref) => {
      return new Canvas(ref.current, {
        width: 440,
        height: 540,
        backgroundColor: "#fff",
      });
    };


    const frontFabricCanvas = initCanvas(frontCanvasRef);
    const backFabricCanvas = initCanvas(backCanvasRef);

    setFrontCanvas(frontFabricCanvas);
    setBackCanvas(backFabricCanvas);

    return () => {
      frontFabricCanvas.dispose();
      backFabricCanvas.dispose();
    };
  }, []);

  useEffect(() => {
    const loadMockupAndTexture = async (canvas, viewIndex) => {
      if (product) {
        try {
          const img = await FabricImage.fromURL(product.mockups[viewIndex]);
          canvas.set("backgroundImage", img);
          img.scaleToWidth(canvas.width);
          img.scaleToHeight(canvas.height);
          canvas.renderAll();

          const clippingRects = viewIndex === 0 ? [
            new Rect({ left: 108, top: 135, width: 210, height: 300, selectable: false, evented: false, stroke: "red", strokeWidth: 1, strokeDashArray: [4, 4], fill: "rgba(0,0,0,0)", id: "clippingRect1"  }),
            new Rect({ left: 200, top: 325, width: 100, height: 50, selectable: false, evented: false, strokeWidth: 1, strokeDashArray: [4, 4], fill: "rgba(0,0,0,0)", id: "clippingRect2" }),
          ] : [
            new Rect({ left: 115, top: 130, width: 210, height: 300, selectable: false, evented: false, stroke: "blue", strokeWidth: 1, strokeDashArray: [4, 4], fill: "rgba(0,0,0,0)", id: "clippingRectBack" }),
          ];

          clippingRects.forEach((rect) => canvas.add(rect));
          canvas.clipPaths = clippingRects;
          canvas.renderAll();
        } catch (error) {
          console.error("Error loading mockup image:", error);
          // toast.error("Failed to load mockup image.");
        }
      }
    };

    if (frontCanvas) loadMockupAndTexture(frontCanvas, 0);
    if (backCanvas) loadMockupAndTexture(backCanvas, 1);
  }, [product, frontCanvas, backCanvas]);

  useEffect(() => {
    const totalCost = frontDesignCost + backDesignCost;
    const calculatedTotalPrice = product.BASE_COST + totalCost; // Adjust based on your requirement
    setTotalPrice(calculatedTotalPrice);
  }, [frontDesignCost, backDesignCost, product.BASE_COST]);

  const handleSellingPriceChange = (e) => {
    setSellingPrice(e.target.value);
  };

  const updateCostAndSize = (widthUnits, heightUnits, isFront) => {

    const area = widthUnits * heightUnits; 
    let newCost = 0;

    if (area <= 25) {  // Equivalent to 5x5
      newCost = product.costStructure.logo || 0;
    } else if (area <= 63) {  // Equivalent to 7x9 (A4)
      newCost = product.costStructure.LessthenA4 || 0;
    } else if (area <= 97.11) {  // Equivalent to 8.3x11.7 (A4)
      newCost = product.costStructure.A4 || 0;
    } else if (area <= 224) {  // Equivalent to 14x16 (A3)
      newCost = product.costStructure.A3 || 0;
    } else if (area <= 294.5) {  // Equivalent to 15.5x19 (Full)
      newCost = product.costStructure.full || 0;
    } else {
      // You can add a condition for any design larger than 15.5x19
      newCost = product.costStructure.extraLarge || 0; 
    }


    if (isFront) {
      setFrontDesignSize({ width: widthUnits, height: heightUnits });
      setFrontDesignCost(newCost);
    } else {
      setBackDesignSize({ width: widthUnits, height: heightUnits });
      setBackDesignCost(newCost);
    }
  };



//   const uploadDesignImage = async (file, designName, designType, setUploadProgress) => {
//     // Generate a unique filename using the design name and current timestamp
//     const timestamp = Date.now();
//     const fileRef = ref(Storage, `designs/${designName}-${designType}-${timestamp}.png`); // Unique path

//     // Create an upload task with resumable upload to monitor progress
//     const uploadTask = uploadBytesResumable(fileRef, file);

//     // Monitor upload progress
//     uploadTask.on('state_changed', 
//       (snapshot) => {
//         // Get upload progress
//         const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
//         setUploadProgress(progress); // Update progress for the circular progress bar
//       }, 
//       (error) => {
//         // Handle errors
//         console.error("Upload failed:", error);
//         // toast.error("Error uploading image.");
//       },
//       async () => {
//         // Upload completed successfully
//         const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
//         return downloadURL;
//       }
//     );

//     // Wait for the upload to complete and get the download URL
//     const snapshot = await uploadTask;
//     const url = await getDownloadURL(snapshot.ref);
//     return url;
// };


//   const handleFileUpload = (e) => {
//     const file = e.target.files[0];
//     const activeCanvas = view === 0 ? frontCanvas : backCanvas;
  
//     if (!file || !file.type.startsWith("image/png")) {
//       // toast.error("Please upload a PNG file.");
//       return;
//     }
  
  
//     const reader = new FileReader();
//     reader.onload = async (event) => {
//       const img = new Image();
//       img.src = event.target.result;
  
//       img.onload = async () => {
//         const dpi = Math.round(img.width / (activeCanvas.width / 25.4));
//         if (dpi < 100) {
//           toast.error("High resolution art required.");
//           return;
//         } else {
//           toast.success("Print quality good.");
//           setIsLoading(true);
//         }
  
//         setDesignFileNames((prev) => ({
//           ...prev,
//           [view === 0 ? "frontDesignImage" : "backDesignImage"]: file.name,
//         }));
  
//         setDesignImages((prev) => ({
//           ...prev,
//           [view === 0 ? "frontDesignImage" : "backDesignImage"]: file,
//         }));
  
//         try {
//           const designImageUrl = await uploadDesignImage(file, designName, view === 0 ? 'front' : 'back', (progress) => {
//             // Update progress during upload
//             setUploadProgress(progress);
//           });
  
//           setDesignImages((prev) => ({
//             ...prev,
//             [view === 0 ? "frontDesignImageUrl" : "backDesignImageUrl"]: designImageUrl,
//           }));
//           const fabricImg = await FabricImage.fromURL(img.src);
  
//           if (!fabricImg.width || !fabricImg.height) {
//             throw new Error("Image dimensions are not available.");
//           }
  
//           // Define different scale factors for front and back
//           const frontScaleFactor = Math.min(210 / fabricImg.width, 300 / fabricImg.height);
//           const backScaleFactor = Math.min(210 / fabricImg.width, 300 / fabricImg.height); // Example values for the back
  
//           const scaleFactor = view === 0 ? frontScaleFactor : backScaleFactor;
  
//           fabricImg.scale(scaleFactor);
  
//           // Define different left, top, and clipPath for front and back
//           const frontLeft = 108;
//           const frontTop = 135;
//           const frontClipPath = new Rect({
//             left: frontLeft,
//             top: frontTop,
//             width: 210,
//             height: 300,
//             absolutePositioned: true,
//           });
  
//           const backLeft = 115; // Example position for the back
//           const backTop = 130;  // Example position for the back
//           const backClipPath = new Rect({
//             left: backLeft,
//             top: backTop,
//             width: 210, // Example width for the back
//             height: 300, // Example height for the back
//             absolutePositioned: true,
//           });
  
//           fabricImg.set({
//             left: view === 0 ? frontLeft + (210 - fabricImg.width * scaleFactor) / 2 : backLeft + (210 - fabricImg.width * scaleFactor) / 2,
//             top: view === 0 ? frontTop + (300 - fabricImg.height * scaleFactor) / 2 : backTop + (300 - fabricImg.height * scaleFactor) / 2,
//             clipPath: view === 0 ? frontClipPath : backClipPath,
//           });
  
//           const initialWidthUnits = (fabricImg.width * scaleFactor) / (210 / 15.5);
//           const initialHeightUnits = (fabricImg.height * scaleFactor) / (300 / 19);
//           updateCostAndSize(initialWidthUnits, initialHeightUnits, view === 0);
  
//           activeCanvas.add(fabricImg);
//           activeCanvas.renderAll();
  
//           // Restrict resizing to the defined rectangle
//           fabricImg.on('scaling', () => {
//             const object = fabricImg;
//             const bounds = {
//               left: view === 0 ? frontLeft : backLeft,
//               top: view === 0 ? frontTop : backTop,
//               width: view === 0 ? 210 : 210,
//               height: view === 0 ? 300 : 300,
//             };
  
//             const newWidth = object.width * object.scaleX;
//             const newHeight = object.height * object.scaleY;
  
//             if (newWidth > bounds.width || newHeight > bounds.height) {
//               object.scaleX = bounds.width / object.width;
//               object.scaleY = bounds.height / object.height;
//             }
  
//             const newLeft = object.left + (object.width * (object.scaleX - 1)) / 2;
//             const newTop = object.top + (object.height * (object.scaleY - 1)) / 2;
  
//             // Prevent moving out of bounds
//             if (newLeft < bounds.left) {
//               object.left = bounds.left;
//             } else if (newLeft + newWidth > bounds.left + bounds.width) {
//               object.left = bounds.left + bounds.width - newWidth;
//             } else {
//               object.left = newLeft;
//             }
  
//             if (newTop < bounds.top) {
//               object.top = bounds.top;
//             } else if (newTop + newHeight > bounds.top + bounds.height) {
//               object.top = bounds.top + bounds.height - newHeight;
//             } else {
//               object.top = newTop;
//             }
  
//             const adjustedWidthUnits = (object.width * object.scaleX) / (210 / 15.5);
//             const adjustedHeightUnits = (object.height * object.scaleY) / (300 / 19);
//             updateCostAndSize(adjustedWidthUnits, adjustedHeightUnits, view === 0);
//           });
  
//           fabricImg.on('scaled', () => {
//             const object = fabricImg;
//             if (object) {
//               object.set({
//                 scaleX: 1,
//                 scaleY: 1,
//               });
//             }
//           });
  
//         } catch (error) {
//           console.error("Error with Fabric Image: ", error);
//           // toast.error("Error loading image. Please try again.");
//         } finally {
//           setIsLoading(false); // Stop loading
//         }
//       };
  
//       img.onerror = () => {
//         // toast.error("Failed to load image. Please try again.");
//         setIsLoading(false);
//       };
//     };
  
//     reader.readAsDataURL(file);
//      setDrawerOpen(false);
//   };



// const handleFileUpload = (e) => {
//   const file = e.target.files[0];
//   const activeCanvas = view === 0 ? frontCanvas : backCanvas;

//   if (!file || !file.type.startsWith("image/png")) {
//     // toast.error("Please upload a PNG file.");
//     return;
//   }

//   const reader = new FileReader();
//   reader.onload = (event) => {
//     const img = new Image();
//     img.src = event.target.result;

//     img.onload = () => {
//       const dpi = Math.round(img.width / (activeCanvas.width / 25.4));

//       // Print quality check removed async
//       if (dpi < 100) {
//         toast.error("High resolution art required.");  // High resolution check
//         return;
//       } else {
//         toast.success("Print quality good.");  // Success message for good print quality
//         setIsLoading(true);
//       }

//       // Set file names for design images
//       const designType = view === 0 ? "front" : "back";  // Front or back based on view
//       setDesignFileNames((prev) => ({
//         ...prev,
//         [`${designType}DesignImage`]: file.name,  // Dynamically set based on design type
//       }));

//       setDesignImages((prev) => ({
//         ...prev,
//         [`${designType}DesignImage`]: file,  // Dynamically set based on design type
//       }));

//       // Wrap asynchronous operations in a try block
//       (async () => {
//         try {
//           const timestamp = Date.now();
//           // Generate a unique filename for the upload based on design type and timestamp
//           const fileRef = ref(Storage, `designs/${designType}-${timestamp}.png`);

//           // Directly upload the image (no progress tracking)
//           await uploadBytes(fileRef, file);

//           // Get the download URL after uploading the file
//           const designImageUrl = await getDownloadURL(fileRef);

//           // Save the download URL to the appropriate key
//           setDesignImages((prev) => ({
//             ...prev,
//             [`${designType}DesignImageUrl`]: designImageUrl,  // Dynamically set based on design type
//           }));

//           const fabricImg = await FabricImage.fromURL(img.src);

//           if (!fabricImg.width || !fabricImg.height) {
//             throw new Error("Image dimensions are not available.");
//           }

//           // Define scale factors and position for front and back
//           const frontScaleFactor = Math.min(210 / fabricImg.width, 300 / fabricImg.height);
//           const backScaleFactor = Math.min(210 / fabricImg.width, 300 / fabricImg.height);

//           const scaleFactor = designType === "front" ? frontScaleFactor : backScaleFactor;

//           fabricImg.scale(scaleFactor);

//           const frontLeft = 108;
//           const frontTop = 135;
//           const frontClipPath = new Rect({
//             left: frontLeft,
//             top: frontTop,
//             width: 210,
//             height: 300,
//             absolutePositioned: true,
//           });

//           const backLeft = 115;
//           const backTop = 130;
//           const backClipPath = new Rect({
//             left: backLeft,
//             top: backTop,
//             width: 210,
//             height: 300,
//             absolutePositioned: true,
//           });

//           fabricImg.set({
//             left: designType === "front" ? frontLeft + (210 - fabricImg.width * scaleFactor) / 2 : backLeft + (210 - fabricImg.width * scaleFactor) / 2,
//             top: designType === "front" ? frontTop + (300 - fabricImg.height * scaleFactor) / 2 : backTop + (300 - fabricImg.height * scaleFactor) / 2,
//             clipPath: designType === "front" ? frontClipPath : backClipPath,
//           });

//           const initialWidthUnits = (fabricImg.width * scaleFactor) / (210 / 15.5);
//           const initialHeightUnits = (fabricImg.height * scaleFactor) / (300 / 19);
//           updateCostAndSize(initialWidthUnits, initialHeightUnits, designType === "front");

//           fabricImg.setControlsVisibility({
//             mt: false, // middle top
//             mb: false, // middle bottom
//             ml: false, // middle left
//             mr: false, // middle right
//             tl: true,  // top left
//             tr: true,  // top right
//             bl: true,  // bottom left
//             br: true   // bottom right
//           });

//           activeCanvas.add(fabricImg);
//           activeCanvas.renderAll();

//           // Restrict resizing to the defined rectangle
//           // fabricImg.on('scaling', () => {
//           //   const object = fabricImg;
//           //   // const bounds = {
//           //   //   left: designType === "front" ? frontLeft : backLeft,
//           //   //   top: designType === "front" ? frontTop : backTop,
//           //   //   width: designType === "front" ? 210 : 210,
//           //   //   height: designType === "front" ? 300 : 300,
//           //   // };

//           //   const newWidth = object.width * object.scaleX;
//           //   const newHeight = object.height * object.scaleY;

//           //   // if (newWidth > bounds.width || newHeight > bounds.height) {
//           //   //   object.scaleX = bounds.width / object.width;
//           //   //   object.scaleY = bounds.height / object.height;
//           //   // }

//           //   const newLeft = object.left + (object.width * (object.scaleX - 1)) / 2;
//           //   const newTop = object.top + (object.height * (object.scaleY - 1)) / 2;

//           //   // Prevent moving out of bounds
//           //   // if (newLeft < bounds.left) {
//           //   //   object.left = bounds.left;
//           //   // } else if (newLeft + newWidth > bounds.left + bounds.width) {
//           //   //   object.left = bounds.left + bounds.width - newWidth;
//           //   // } else {
//           //   //   object.left = newLeft;
//           //   // }

//           //   // if (newTop < bounds.top) {
//           //   //   object.top = bounds.top;
//           //   // } else if (newTop + newHeight > bounds.top + bounds.height) {
//           //   //   object.top = bounds.top + bounds.height - newHeight;
//           //   // } else {
//           //   //   object.top = newTop;
//           //   // }

//           //   const adjustedWidthUnits = (object.width * object.scaleX) / (210 / 15.5);
//           //   const adjustedHeightUnits = (object.height * object.scaleY) / (300 / 19);
//           //   updateCostAndSize(adjustedWidthUnits, adjustedHeightUnits, designType === "front");
//           // });


//           fabricImg.on('scaling', () => {
//             const object = fabricImg;
            
//             // Define the boundary area (this can be dynamic based on your design area)
//             const bounds = {
//                 left: view === 0 ? frontLeft : backLeft,
//                 top: view === 0 ? frontTop : backTop,
//                 width: 210,  // Width of the boundary
//                 height: 300, // Height of the boundary
//             };
        
//             // Calculate the aspect ratio of the image
//             const aspectRatio = object.width / object.height;
//             let newWidth = object.width * object.scaleX;
//             let newHeight = object.height * object.scaleY;
        
//             // Prevent the image from going outside the bounds (scale the image to fit within the bounds)
//             if (newWidth > bounds.width) {
//                 newWidth = bounds.width;
//                 newHeight = newWidth / aspectRatio;
//                 object.scaleX = newWidth / object.width;
//                 object.scaleY = newHeight / object.height;
//             }
        
//             if (newHeight > bounds.height) {
//                 newHeight = bounds.height;
//                 newWidth = newHeight * aspectRatio;
//                 object.scaleX = newWidth / object.width;
//                 object.scaleY = newHeight / object.height;
//             }
        
//             // Calculate the new left and top positions after scaling
//             const newLeft = object.left + (object.width * (object.scaleX - 1)) / 2;
//             const newTop = object.top + (object.height * (object.scaleY - 1)) / 2;
        
//             // Prevent the object from going out of bounds horizontally (left)
//             if (newLeft < bounds.left) {
//                 object.left = bounds.left;
//             } else if (newLeft + newWidth > bounds.left + bounds.width) {
//                 object.left = bounds.left + bounds.width - newWidth;
//             } else {
//                 object.left = newLeft;
//             }
        
//             // Prevent the object from going out of bounds vertically (top)
//             // if (newTop < bounds.top) {
//             //     object.top = bounds.top;
//             // } else if (newTop + newHeight > bounds.top + bounds.height) {
//             //     // When scaling exceeds bottom boundary, adjust top smoothly
//             //     const excessBottom = newTop + newHeight - (bounds.top + bounds.height);
//             //     object.top = newTop - excessBottom;
        
//             //     // Ensure the top doesn't go above the top boundary if the object scales upward
//             //     if (object.top < bounds.top) {
//             //         object.top = bounds.top;
//             //     }
//             // } else {
//             //     object.top = newTop;
//             // }
        
//             // Optional: Update the cost/size based on the adjusted dimensions
//             const adjustedWidthUnits = (object.width * object.scaleX) / (210 / 15.5);
//             const adjustedHeightUnits = (object.height * object.scaleY) / (300 / 19);
//             updateCostAndSize(adjustedWidthUnits, adjustedHeightUnits, view === 0);
//         });        
          
//           fabricImg.on('scaled', () => {
//             const object = fabricImg;
//             if (object) {
//               object.set({
//                 scaleX: 1,
//                 scaleY: 1,
//               });
//             }
//           });

//         } catch (error) {
//           console.error("Error with Fabric Image: ", error);
//           // toast.error("Error loading image. Please try again.");
//         } finally {
//           setIsLoading(false); // Stop loading
//         }
//       })();  // Immediately invoked async function

//     };

//     img.onerror = () => {
//       // toast.error("Failed to load image. Please try again.");
//       setIsLoading(false);
//     };
//   };

//   reader.readAsDataURL(file);
//   setDrawerOpen(false);
// };


const handleFileUpload = (e) => {
  const file = e.target.files[0];
  const activeCanvas = view === 0 ? frontCanvas : backCanvas;

  if (!file || !file.type.startsWith("image/png")) {
    // toast.error("Please upload a PNG file.");
    return;
  }

  const reader = new FileReader();
  reader.onload = (event) => {
    const img = new Image();
    img.src = event.target.result;

    img.onload = function () {
      const dpi = Math.round(img.width / (activeCanvas.width / 25.4));
      if (dpi < 100) {
        toast.error("Print quality bad, high resolution art required.");
        return;
      } else {
        toast.success("Print quality good.");
      }

      // Immediately add the image to the canvas without quality check
      const designType = view === 0 ? "front" : "back";  // Front or back based on view

      setDesignFileNames((prev) => ({
        ...prev,
        [`${designType}DesignImage`]: file.name,  // Dynamically set based on design type
      }));

      setDesignImages((prev) => ({
        ...prev,
        [`${designType}DesignImage`]: file,  // Dynamically set based on design type
      }));

      (async () => {
        try {
          // const timestamp = Date.now();
          // const fileRef = ref(Storage, `designs/${designType}-${timestamp}.png`);
          // await uploadBytes(fileRef, file);

          // const designImageUrl = await getDownloadURL(fileRef);
          // setDesignImages((prev) => ({
          //   ...prev,
          //   [`${designType}DesignImageUrl`]: designImageUrl,  // Dynamically set based on design type
          // }));

          const fabricImg = await FabricImage.fromURL(img.src);

          if (!fabricImg.width || !fabricImg.height) {
            throw new Error("Image dimensions are not available.");
          }

          // Define scale factors and position for front and back
          const frontScaleFactor = Math.min(210 / fabricImg.width, 300 / fabricImg.height);
          const backScaleFactor = Math.min(210 / fabricImg.width, 300 / fabricImg.height);

          const scaleFactor = designType === "front" ? frontScaleFactor : backScaleFactor;

          fabricImg.scale(scaleFactor);

          const frontLeft = 108;
          const frontTop = 135;
          const frontClipPath = new Rect({
            left: frontLeft,
            top: frontTop,
            width: 210,
            height: 300,
            absolutePositioned: true,
          });

          const backLeft = 115;
          const backTop = 130;
          const backClipPath = new Rect({
            left: backLeft,
            top: backTop,
            width: 210,
            height: 300,
            absolutePositioned: true,
          });

          fabricImg.set({
            left: designType === "front" ? frontLeft + (210 - fabricImg.width * scaleFactor) / 2 : backLeft + (210 - fabricImg.width * scaleFactor) / 2,
            top: designType === "front" ? frontTop + (300 - fabricImg.height * scaleFactor) / 2 : backTop + (300 - fabricImg.height * scaleFactor) / 2,
            clipPath: designType === "front" ? frontClipPath : backClipPath,
          });

          // Add the image to the canvas immediately
          activeCanvas.add(fabricImg);
          activeCanvas.renderAll();



          const initialWidthUnits = (fabricImg.width * scaleFactor) / (210 / 15.5);
          const initialHeightUnits = (fabricImg.height * scaleFactor) / (300 / 19);
          updateCostAndSize(initialWidthUnits, initialHeightUnits, designType === "front");

          fabricImg.setControlsVisibility({
            mt: false, // middle top
            mb: false, // middle bottom
            ml: false, // middle left
            mr: false, // middle right
            tl: true,  // top left
            tr: true,  // top right
            bl: true,  // bottom left
            br: true   // bottom right
          });

          fabricImg.on('scaling', () => {
            const object = fabricImg;
            const bounds = {
              left: view === 0 ? frontLeft : backLeft,
              top: view === 0 ? frontTop : backTop,
              width: 210,  // Width of the boundary
              height: 300, // Height of the boundary
            };

            const aspectRatio = object.width / object.height;
            let newWidth = object.width * object.scaleX;
            let newHeight = object.height * object.scaleY;

            if (newWidth > bounds.width) {
              newWidth = bounds.width;
              newHeight = newWidth / aspectRatio;
              object.scaleX = newWidth / object.width;
              object.scaleY = newHeight / object.height;
            }

            if (newHeight > bounds.height) {
              newHeight = bounds.height;
              newWidth = newHeight * aspectRatio;
              object.scaleX = newWidth / object.width;
              object.scaleY = newHeight / object.height;
            }

            const newLeft = object.left + (object.width * (object.scaleX - 1)) / 2;
            const newTop = object.top + (object.height * (object.scaleY - 1)) / 2;

            if (newLeft < bounds.left) {
              object.left = bounds.left;
            } else if (newLeft + newWidth > bounds.left + bounds.width) {
              object.left = bounds.left + bounds.width - newWidth;
            } else {
              object.left = newLeft;
            }

            const adjustedWidthUnits = (object.width * object.scaleX) / (210 / 15.5);
            const adjustedHeightUnits = (object.height * object.scaleY) / (300 / 19);
            updateCostAndSize(adjustedWidthUnits, adjustedHeightUnits, view === 0);
          });

          fabricImg.on('scaled', () => {
            const object = fabricImg;
            if (object) {
              object.set({
                scaleX: 1,
                scaleY: 1,
              });
            }
          });

           const timestamp = Date.now();
          const fileRef = ref(Storage, `designs/${designType}-${timestamp}.png`);
          await uploadBytes(fileRef, file);

          const designImageUrl = await getDownloadURL(fileRef);
          setDesignImages((prev) => ({
            ...prev,
            [`${designType}DesignImageUrl`]: designImageUrl,  // Dynamically set based on design type
          }));

        } catch (error) {
          console.error("Error with Fabric Image: ", error);
        } finally {
          setIsLoading(false); // Stop loading
        }
      })();  // Immediately invoked async function

    };

    img.onerror = () => {
      // toast.error("Failed to load image. Please try again.");
      setIsLoading(false);
    };
  };

  reader.readAsDataURL(file);
  setDrawerOpen(false);
};



  // const saveFinalDesign = async () => {
  //   const activeCanvas = view === 0 ? frontCanvas : backCanvas;
  
  //   // Ensure we are clipping the content inside the red rectangle
  //   const clippingRect = view === 0
  //     ? new Rect({ left: 110, top: 145, width: 210, height: 300 })
  //     : new Rect({ left: 115, top: 130, width: 210, height: 300 });
  
  //   // Set the clipping path for the canvas
  //   activeCanvas.clipPath = clippingRect;
  //   activeCanvas.renderAll();
  
  //   // Export the final design as a PNG within the clipping path
  //   const finalDesignDataURL = activeCanvas.toDataURL({ format: 'png' });
  
  //   // Save the design to Firebase Storage
  //   const finalDesignBlob = dataURItoBlob(finalDesignDataURL);
  //   const timestamp = Date.now();
  //   const fileRef = ref(Storage, `designs/${designName}-final-${timestamp}.png`);
  //   await uploadBytes(fileRef, finalDesignBlob);
  //   const finalDesignUrl = await getDownloadURL(fileRef);
  
  //   return finalDesignUrl;
  // };


  // const saveFinalDesign = async () => {
  //   const activeCanvas = view === 0 ? frontCanvas : backCanvas;
    
  //   // Define the clipping path (red rectangle) based on the front and back canvas dimensions
  //   const clippingRect = view === 0
  //     ? new Rect({ left: 110, top: 145, width: 210, height: 300 })
  //     : new Rect({ left: 115, top: 130, width: 210, height: 300 });
  
  //   // Set the clipping path for the canvas
  //   activeCanvas.clipPath = clippingRect;
  //   activeCanvas.renderAll();
    
  //   // Export the final design as a high-resolution PNG within the clipping path
  //   const finalDesignDataURL = activeCanvas.toDataURL({
  //     format: 'png',
  //     quality: 1.0, // Maximum quality (high resolution)
  //     multiplier: 2, // This multiplier increases the resolution of the exported image. Use a higher value for more resolution (e.g., multiplier: 2 or 3 for high-res)
  //   });
  
  //   // Convert the data URI to a Blob for uploading to Firebase
  //   const finalDesignBlob = dataURItoBlob(finalDesignDataURL);
  //   const timestamp = Date.now();
    
  //   // Generate the file reference path for Firebase Storage
  //   const fileRef = ref(Storage, `designs/${designName}-final-${timestamp}.png`);
    
  //   // Upload the image to Firebase Storage
  //   await uploadBytes(fileRef, finalDesignBlob);
    
  //   // Get the download URL of the uploaded image
  //   const finalDesignUrl = await getDownloadURL(fileRef);
    
  //   return finalDesignUrl;
  // };
  
  // // Helper function to convert Data URI to Blob

  // const dataURItoBlob = (dataURI) => {
  //   const byteString = dataURI.split(',')[1];
  //   const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
  //   const ia = new Uint8Array(atob(byteString).split('').map(char => char.charCodeAt(0)));
  //   return new Blob([ia], { type: mimeString });
  // };

  

  const handleSubmit = async (status) => {
    // setIsSubmitting(true);

    // Ensure at least one design has been uploaded
    if (!designImages.frontDesignImage && !designImages.backDesignImage) {
      // toast.error("Please upload at least one design image (front or back).");
      return;
    }


    const frontImageUrl = designImages.frontDesignImageUrl || null;
    const backImageUrl = designImages.backDesignImageUrl || null;

    // const frontImageUrl = await saveFinalDesign('front');  
    // const backImageUrl = await saveFinalDesign('back'); 

    if (!frontImageUrl && !backImageUrl) {
      // toast.error("Failed to upload images. Please try again.");
      return;
    }

    setIsSubmitting(true);

    try {
      // Function to convert Data URI to Blob
      const dataURItoBlob = (dataURI) => {
        const byteString = dataURI.split(',')[1];
        const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
        const ia = new Uint8Array(atob(byteString).split('').map(char => char.charCodeAt(0)));
        return new Blob([ia], { type: mimeString });
      };

      // Save final canvas design to Firebase Storage
      const saveCanvasToStorage = async (canvas, canvasName) => {
        canvas.getObjects("rect").forEach((rect) => rect.set({ visible: false })); // Hide any visible rectangles
        canvas.renderAll();
        const timestamp = Date.now();
        const canvasPNG = canvas.toDataURL({ format: 'png' }); // Save as PNG for quality
        const canvasRef = ref(Storage, `mockups/${designName}-${canvasName}-${timestamp}.png`);
        await uploadBytes(canvasRef, dataURItoBlob(canvasPNG));
        const url = await getDownloadURL(canvasRef);
        return url;
      };

     

      

      // Generate SKUs for different sizes
      const generateSKUs = () => {
        const randomNum = Math.floor(Math.random() * 1000000);
        const sizes = ['S', 'M', 'L', 'XL', 'XXL'];
        return sizes.map(size => `u-${randomNum}-${size}`);
      };
      const skus = generateSKUs();

      // Export the final combined design for front and back mockups
      const frontMockupUrl = await saveCanvasToStorage(frontCanvas, 'front');
      const backMockupUrl = await saveCanvasToStorage(backCanvas, 'back');

      // Create design document in Firestore
      const designRef = doc(collection(db, 'shops', shop, 'designs'));
      await setDoc(designRef, {
        designName,
        description,
        sellingPrice,
        totalPrice,
        frontDesign: frontImageUrl, // Use the URL from state
        backDesign: backImageUrl,   // Use the URL from state
        images: {
          front: frontMockupUrl,
          back: backMockupUrl,
        },
        costs: {
          front: frontDesignCost,
          back: backDesignCost,
        },
        Printsizes: {
          front: frontDesignSize,
          back: backDesignSize,
        },
        productName: product.name, // Use product data
        productGSM: product.GSM,
        SKUs: skus, // Use product GSM
        baseCost: product.BASE_COST,
        PrintCost: frontDesignCost + backDesignCost,
        timestamp: serverTimestamp() 
      });

      // Create payload for the external API
      const payload = {
        productName: productName,
        frontImage: frontMockupUrl,
        backImage: backMockupUrl,
        frontDesign: frontImageUrl, // Use the URL from state
        backDesign: backImageUrl,
        shop: shop,
        designName: designName,
        description: description,
        sellingPrice: sellingPrice,
        SKUs: skus,
        PrintCost: frontDesignCost + backDesignCost,
      };

      // Send the data to your external API (to create a draft product)
      await axios.post('https://ufb-1.onrender.com/api/create-active-product', {
        ...payload,
        status, // Add status to the payload here
      });

      // toast.success(
      //   <>
      //     <span>Design uploaded successfully! </span>
      //     <a href={`/products?shop=${shop}`} style={{ color: 'blue', textDecoration: 'underline' }}>
      //       Go to product page
      //     </a>
      //   </>,
      // );

      // Redirect after successful submission
      toast.success('Design uploaded successfully!');
      setShowModal(true);
      // navigate(`/products?shop=${shop}`);
    } catch (error) {
      console.error("Error uploading design:", error);
      toast.error("Failed to upload design.");
    } finally {
      setIsSubmitting(false);
    }
};

const handleGoToProducts = () => {
  // Navigate to the products page after clicking "Go"
  navigate(`/products?shop=${shop}`);
};

  const handleRotate = () => {
    // event.preventDefault(); 
    const activeCanvas = view === 0 ? frontCanvas : backCanvas;
    const activeObjects = activeCanvas.getActiveObjects();

    activeObjects.forEach((obj) => {
      obj.rotate(obj.angle + 90);
      obj.setCoords();
    });
    activeCanvas.renderAll();
  };

  const handleDelete = () => {
    // event.preventDefault(); 
    const activeCanvas = view === 0 ? frontCanvas : backCanvas;
    const activeObjects = activeCanvas.getActiveObjects();

    activeObjects.forEach((obj) => {
      activeCanvas.remove(obj);
    });
    activeCanvas.discardActiveObject();
    activeCanvas.renderAll();
  };


  const setOpenDrawer = (isOpen) => {
    setDrawerOpen(isOpen);
  };

  return (
    <div className="one">
       <ToastContainer />
    <Header/>
    <div className="design-upload-container">
    <Sidebar />
     <div className={`drawer ${drawerOpen ? 'open' : ''}`}>
     <div className="drawer-header">
              <button className="close-drawer" onClick={() => setOpenDrawer(false)}>
                <FaTimes/>
              </button>
            </div>

      <div className="form-wrapper">

        <h2 className='hideee'>Design Upload</h2>



{/* {isLoading && (
  <div className="loading-overlay">
    <div className="loader-container">
      <div className="progress-container">
        <CircularProgressbar
          value={uploadProgress}
          text={`${Math.round(uploadProgress)}%`} 
          
          styles={buildStyles({
           pathColor: '#FF3E4D', 
            textColor: '#FF3E4D',
            textSize: '30px',      
            fontWeight: 'extra-bold',    
          })}
        />
      </div>
    </div>
  </div>
)} */}

        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label>Upload Design Image:</label>
            <input
              type="file"
              accept=".png"
              onChange={handleFileUpload}
              multiple
              style={{ display: 'none' }} // Hide the default file input
              id="designImage"
            />
            <label htmlFor="designImage" className="custom-file-upload">
              + Add Image
            </label>
            {/* {designFileNames[view === 0 ? "frontDesignImage" : "backDesignImage"] && (
              <p>Uploaded: {designFileNames[view === 0 ? "frontDesignImage" : "backDesignImage"]}</p>
            )} */}

{Object.keys(designFileNames).map((key) => {
  if (designFileNames[key]) {
    return (
      <p key={key}>Uploaded: {designFileNames[key]}</p>
    );
  }
  return null;
})}
          </div>
          <div className="form-group">
            <label htmlFor="designName">Design Name:</label>
            <input
              type="text"
              id="designName"
              value={designName}
              onChange={(e) => setDesignName(e.target.value)}
              placeholder="Design Name"
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="description">Description:</label>
            <textarea
              id="description"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              placeholder="Description"
              required
            />
          </div>
          <div className="design-details">
            <p><label>GSM: {product.GSM}</label></p>
            <p><label>Front Design Size: {frontDesignSize.height.toFixed(1)} x {frontDesignSize.width.toFixed(1)} units</label></p>
            <p><label>Front Design Cost: ₹{frontDesignCost}</label></p>
            <p><label>Back Design Size: {backDesignSize.height.toFixed(1)} x {backDesignSize.width.toFixed(1)} units</label></p>
            <p><label>Back Design Cost: ₹{backDesignCost}</label></p>
            <p><label>Total Cost: ₹{totalPrice} + {product.tax} + Shipping </label></p>
            {/* <p><label>Total Cost: ₹{frontDesignCost + backDesignCost}</label></p> */}
          </div>
            
          {/* <div className="total-price">
            <p><label>Total Cost: ₹{totalPrice} + {product.tax} + Shipping </label></p>
          </div> */}

          <div className="form-group">
            <label htmlFor="sellingPrice">Selling Price:</label>
            <input
              type="number"
              id="sellingPrice"
              value={sellingPrice}
              onChange={handleSellingPriceChange}
              placeholder="Enter Selling Price"
              required
            />
          </div>

          {/* Total Price Display */}
          {/* <div className="total-price">
            <p><label>Total Cost: ₹{totalPrice}</label></p>
          </div> */}
          {/* <button type="submit" className='desktop-submit'>Submit</button> */}
          <div className="Beee">
          <button type="button" disabled={isSubmitting} onClick={() => handleSubmit('active')} className='desktop-submit'>
  {isSubmitting ? 'Submitting...' : 'Make It Live!'}
</button>
<button type="button" disabled={isSubmitting} onClick={() => handleSubmit('draft')} className='desktop-draft'>
  {isSubmitting ? 'Saving as Draft...' : 'Make Draft'}
</button>
</div>
<div className='skualert'>*Do not change the SKU of the Shopify product after creation.</div>
        </form>
        </div>

        {showModal && (
        <Modal>
          <div className="modal-content">
            <h2>Your products has been created successfully!</h2>
           
            <button onClick={handleGoToProducts}>Go to products page!</button>
          </div>
        </Modal>
      )}
      

      </div>


      <div className="mockup">
      <div className="view-and-customize">
         <select onChange={(e) => setView(Number(e.target.value))}  className="view-select">
            <option value="0">Front</option>
            <option value="1">Back</option>
          </select>
      
          <button onClick={() => setOpenDrawer(true)} className="customize-button">
              Customize
          </button>
           <button onClick={handleRotate} className="control-button"><FaUndo/></button>
          <button  onClick={handleDelete} className="control-button"><FaTrash/></button>
</div>
        <div style={{ display: view === 0 ? 'block' : 'none' }}>
          <canvas ref={frontCanvasRef} />
        </div>
        <div style={{ display: view === 1 ? 'block' : 'none' }}>
          <canvas ref={backCanvasRef} />
        </div>
        
      </div>
      
    </div>
    </div>
  );
};

export default DesignUpload;