import React, { useState } from 'react';
import { sendPasswordResetEmail } from 'firebase/auth';
import { auth } from '../firebase';
import '../style/forgetPassword.css'

const ForgetPassword = () => {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');

  const handlePasswordReset = (e) => {
    e.preventDefault();
    if (email) {
      sendPasswordResetEmail(auth, email)
        .then(() => {
          setMessage('Password reset email sent!');
        })
        .catch((error) => {
          setMessage('Error sending password reset email: ' + error.message);
        });
    } else {
      setMessage('Please enter your email address.');
    }
    
  };

  return (
    <div className='forgot-password-container'>
      <form onSubmit={handlePasswordReset} className='forgot-password-form'>
        <h2>Forgot Password</h2>
        <label htmlFor='email'>
          Email:
          <input
            type='email'
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
        </label>
        <button type='submit'>Send Reset Email</button>
        {message && <p>{message}</p>}
      </form>
    </div>
  );
};

export default ForgetPassword;
