// import React, { useState, useEffect, useCallback } from 'react';
// import { doc, getDoc, updateDoc, Timestamp, setDoc, addDoc, collection } from 'firebase/firestore';
// import { db, auth } from '../firebase';
// import '../style/Header.css';
// import { ToastContainer, toast } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';
// import Wallet from './Wallet';
// import { useModal } from './ModalContext';


// const Header = ({ toggleSidebar }) => {
//   const [balance, setBalance] = useState(0);
//   const [error, setError] = useState('');

//   const { isModalOpen, setIsModalOpen } = useModal();
//   const user = auth.currentUser;


//   const fetchWalletBalance = async () => {
//     const user = auth.currentUser;

//     // if (!user || !user.uid) {
//     //   toast.error('User not logged in in. Please log in (OR) Refresh.');
//     //   return;
//     // }
//     try {
//       // Ensure the document path has an even number of segments
//       const userRef = doc(db, 'users', user.uid, 'wallet', 'walletDetails');
//       const docSnap = await getDoc(userRef);
//       if (docSnap.exists()) {
//         const userData = docSnap.data();
//         setBalance(userData.balance || 0);
//       } else {
//         setBalance(0); // If no wallet document exists, default to 0 balance
//       }
//     } catch (error) {
//       console.error('Error fetching wallet balance:', error);
//       setError('Failed to fetch wallet balance. Please try again.');
//     }
//   };

//   useEffect(() => {
//     fetchWalletBalance();
//   }, []);

//   return (
//     <header className="header">
//       <ToastContainer />
//       <div className="logo">
//         <img src="/path/to/logo.png" alt="Logo" />
//       </div>
//       <div className="menu-icon" onClick={toggleSidebar}>
//         <span className="bar"></span>
//         <span className="bar"></span>
//         <span className="bar"></span>
//       </div>
//       <div className="balance-info">
//         <div className="wallet-section">
//           <img
//             src="https://sr-sidebar.shiprocket.in/assets/svg/wallet.svg"
//             alt="Wallet"
//             className="wallet-icon"
//           />
//           <p className="wallet-balance">₹{balance}</p>
//           <img
//             src="https://sr-sidebar.shiprocket.in/assets/svg/refresh-icon.svg"
//             onClick={fetchWalletBalance}
//             alt="Refresh"
//             className="refresh-icon"
//           />
//           <button onClick={() => setIsModalOpen(true)} className="recharge-btn">
//             Recharge Wallet
//           </button>
//         </div>
        
//         {isModalOpen && (<Wallet />)}
//       </div>
//     </header>
//   );
// };
// export default Header;




// import React, { useState, useEffect } from 'react';
// import { doc, getDoc } from 'firebase/firestore';
// import { db, auth } from '../firebase';
// import '../style/Header.css';
// import { ToastContainer, toast } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';
// import Wallet from './Wallet';
// import { useModal } from './ModalContext';
// import { Link , useLocation} from 'react-router-dom';

// const Header = ({ toggleSidebar }) => {
//   const [balance, setBalance] = useState(0);
//   const [isSidebarOpen, setIsSidebarOpen] = useState(false);
//   const { isModalOpen, setIsModalOpen } = useModal();
//   const user = auth.currentUser;
//   const location = useLocation();
//   const queryParams = new URLSearchParams(location.search);
//   const shop = queryParams.get('shop');

//   const fetchWalletBalance = async () => {
//     try {
//       if (!user || !user.uid) {
//         toast.error('User not logged in. Please log in (OR) Refresh.');
//         return;
//       }
//       const userRef = doc(db, 'users', user.uid, 'wallet', 'walletDetails');
//       const docSnap = await getDoc(userRef);
//       if (docSnap.exists()) {
//         const userData = docSnap.data();
//         setBalance(userData.balance || 0);
//       } else {
//         setBalance(0);
//       }
//     } catch (error) {
//       console.error('Error fetching wallet balance:', error);
//       toast.error('Failed to fetch wallet balance. Please try again.');
//     }
//   };

//   useEffect(() => {
//     fetchWalletBalance();
//   }, []);

//   // const handleSidebarToggle = () => {
//   //   setIsSidebarOpen(!isSidebarOpen);
//   // };

//   return (
//     <>
//       <header className="header">
//         <ToastContainer />
//         <div className="logo">
//         <Link to={`/dashboard?shop=${encodeURIComponent(shop)}`}>
//     <img src="logo.png" alt="Logo" />
// </Link>
//         </div>
//         {/* <div className="menu-icon" onClick={handleSidebarToggle}>
//           <span className="bar"></span>
//           <span className="bar"></span>
//           <span className="bar"></span>
//         </div> */}
//         <div className="balance-info">
//           <div className="wallet-section">
//             <img
//               src="https://sr-sidebar.shiprocket.in/assets/svg/wallet.svg"
//               alt="Wallet"
//               className="wallet-icon"
//             />
//             <p className="wallet-balance">₹{balance}</p>
//             <img
//               src="https://sr-sidebar.shiprocket.in/assets/svg/refresh-icon.svg"
//               onClick={fetchWalletBalance}
//               alt="Refresh"
//               className="refresh-icon"
//             />
//             <button onClick={() => setIsModalOpen(true)} className="recharge-btn">
//               Recharge Wallet
//             </button>
//           </div>
//           {isModalOpen && <Wallet />}
//         </div>
//       </header>

//       {/* {isSidebarOpen && (
//         <div className="sidebar">
//           <button className="close-btn" onClick={handleSidebarToggle}>
//             &times;
//           </button>
//           <nav className="sidebar-nav">
//             <ul>
//               <li>Home</li>
//               <li>Profile</li>
//               <li>Settings</li>
//               <li>Logout</li>
//             </ul>
//           </nav>
//         </div>
//       )} */}
//     </>
//   );
// };

// export default Header;


import React, { useState, useEffect } from 'react';
import { doc, getDoc } from 'firebase/firestore';
import { db, auth } from '../firebase';
import '../style/Header.css';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Wallet from './Wallet';
import { useModal } from './ModalContext';
import { Link, useLocation } from 'react-router-dom';

const Header = ({ toggleSidebar }) => {
  const [balance, setBalance] = useState(0);
  const { isModalOpen, setIsModalOpen } = useModal();
  const user = auth.currentUser;
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const shop = queryParams.get('shop');

  const fetchWalletBalance = async () => {
    try {
      if (!user || !user.uid) {
        toast.error('User not logged in. Please log in (OR) Refresh.');
        return;
      }
      const userRef = doc(db, 'users', user.uid, 'wallet', 'walletDetails');
      const docSnap = await getDoc(userRef);
      if (docSnap.exists()) {
        const userData = docSnap.data();
        setBalance(userData.balance || 0);
      } else {
        setBalance(0);
      }
    } catch (error) {
      console.error('Error fetching wallet balance:', error);
      toast.error('Failed to fetch wallet balance. Please try again.');
    }
  };

  useEffect(() => {
    fetchWalletBalance();
  }, []);

  return (
    <>
      <header className="header">
        <ToastContainer />
        
        {/* Hamburger Menu Icon */}
        <div className="hamburger" onClick={toggleSidebar}>
          <span className="bar"></span>
          <span className="bar"></span>
          <span className="bar"></span>
        </div>

        {/* <div className="logo">
          <Link to={`/dashboard?shop=${encodeURIComponent(shop)}`}>
            <img src="logo.png" alt="Logo" />
          </Link>
        </div> */}

        <div className="balance-info">
          <div className="wallet-section">
            <img
              src="https://sr-sidebar.shiprocket.in/assets/svg/wallet.svg"
              alt="Wallet"
              className="wallet-icon"
            />
            <p className="wallet-balance">₹{balance}</p>
            <img
              src="https://sr-sidebar.shiprocket.in/assets/svg/refresh-icon.svg"
              onClick={fetchWalletBalance}
              alt="Refresh"
              className="refresh-icon"
            />
            <button onClick={() => setIsModalOpen(true)} className="recharge-btn">
              Recharge Wallet
            </button>
          </div>
          {isModalOpen && <Wallet />}
        </div>
      </header>
    </>
  );
};

export default Header;


// import React, { useState, useEffect } from 'react';
// import { doc, getDoc } from 'firebase/firestore';
// import { db, auth } from '../firebase';
// import '../style/Header.css';
// import { ToastContainer, toast } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';
// import Wallet from './Wallet';
// import { useModal } from './ModalContext';
// import { Link, useLocation } from 'react-router-dom';

// const Header = ({ toggleSidebar }) => {
//   const [balance, setBalance] = useState(0);
//   const { isModalOpen, setIsModalOpen } = useModal();
//   const user = auth.currentUser;
//   const location = useLocation();
//   const queryParams = new URLSearchParams(location.search);
//   const shop = queryParams.get('shop');

//   const fetchWalletBalance = async () => {
//     try {
//       if (!user || !user.uid) {
//         toast.error('User not logged in. Please log in (OR) Refresh.');
//         return;
//       }
//       const userRef = doc(db, 'users', user.uid, 'wallet', 'walletDetails');
//       const docSnap = await getDoc(userRef);
//       if (docSnap.exists()) {
//         const userData = docSnap.data();
//         setBalance(userData.balance || 0);
//       } else {
//         setBalance(0);
//       }
//     } catch (error) {
//       console.error('Error fetching wallet balance:', error);
//       toast.error('Failed to fetch wallet balance. Please try again.');
//     }
//   };

//   useEffect(() => {
//     fetchWalletBalance();
//   }, []);

//   return (
//     <>
//       <header className="header">
//         <ToastContainer />
        
//         {/* Hamburger Menu Icon */}
//         <div className="hamburger" onClick={toggleSidebar}>
//           <span className="bar"></span>
//           <span className="bar"></span>
//           <span className="bar"></span>
//         </div>

//         <div className="logo">
//           <Link to={`/dashboard?shop=${encodeURIComponent(shop)}`}>
//             <img src="logo.png" alt="Logo" />
//           </Link>
//         </div>

//         <div className="balance-info">
//           <div className="wallet-section">
//             <img
//               src="https://sr-sidebar.shiprocket.in/assets/svg/wallet.svg"
//               alt="Wallet"
//               className="wallet-icon"
//             />
//             <p className="wallet-balance">₹{balance}</p>
//             <img
//               src="https://sr-sidebar.shiprocket.in/assets/svg/refresh-icon.svg"
//               onClick={fetchWalletBalance}
//               alt="Refresh"
//               className="refresh-icon"
//             />
//             <button onClick={() => setIsModalOpen(true)} className="recharge-btn">
//               Recharge Wallet
//             </button>
//           </div>
//           {isModalOpen && <Wallet />}
//         </div>
//       </header>
//     </>
//   );
// };

// export default Header;
