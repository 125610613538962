import React from 'react';
import { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import Header from '../components/Header';
import '../style/Product.css';
import Sidebar from '../components/Sidebar';

const products = [
  
  {
    id: 1,
    name: 'White Oversized T-shirt',
    title: 'whot',
    images: ['whiteF5.png', '6.png'],
    description: 'White Oversized T-shirt',
    fabric: '100% Cotton',
    BASE_COST: 180,
    tax: 'tax',
    mockups: ['whiteF5.png', '6.png'],
    texture: '21.png',
    GSM: '180 GSM',
    costStructure: {
      logo: 60,
      LessthenA4: 104,
      A4: 130,
      A3: 200,
     full: 250,
    },
  },
  
  
  {
    id: 2,
    name: 'White Oversized T-shirt',
    title: 'whot',
    images: ['whiteF5.png', '6.png'],
    description: 'White Oversized T-shirt',
    fabric: '100% Cotton',
    BASE_COST: 250,
    tax: 'tax',
    mockups: ['whiteF5.png', '6.png'],
    texture: '21.png',
    GSM: '220 GSM',
    costStructure: {
      logo: 60,
      LessthenA4: 104,
      A4: 130,
      A3: 200,
     full: 250,
    },
  },



  {
    id: 3,
    name: 'White Oversized T-shirt',
    title: 'whot',
    images: ['whiteF5.png', '6.png'],
    description: 'White Oversized T-shirt',
    fabric: '100% Cotton',
    BASE_COST: 280,
    tax: 'tax',
    mockups: ['whiteF5.png', '6.png'],
    texture: '21.png',
    GSM: '240 GSM',
    costStructure: {
      logo: 60,
      LessthenA4: 104,
      A4: 130,
      A3: 200,
     full: 250,
    },
  },


 


  {
    id: 4,
    name: 'Black Oversized T-shirt',
    title: 'blot',
    images: ['blackF1.png', '3.png'],
    description: 'Black Oversized T-shirt',
    fabric: '100% Cotton',
    BASE_COST: 180,
    tax: 'tax',
    mockups: ['blackF1.png', '3.png'],
    texture: '21.png',
    GSM: '180 GSM',
    costStructure: {
      logo: 60,
      LessthenA4: 104,
      A4: 175,
      A3: 250,
     full: 300,
    },
  },


  {
    id: 5,
    name: 'Black Oversized T-shirt',
    title: 'blot',
    images: ['blackF1.png', '3.png'],
    description: 'Black Oversized T-shirt',
    fabric: '100% Cotton',
    BASE_COST: 250,
    tax: 'tax',
    mockups: ['blackF1.png', '3.png'],
    texture: '21.png',
    GSM: '220 GSM',
    costStructure: {
      logo: 60,
      LessthenA4: 104,
      A4: 175,
      A3: 250,
     full: 300,
    },
  },



  {
    id: 6,
    name: 'Black Oversized T-shirt',
    title: 'blot',
    images: ['blackF1.png', '3.png'],
    description: 'Black Oversized T-shirt',
    fabric: '100% Cotton',
    BASE_COST: 280,
    tax: 'tax',
    mockups: ['blackF1.png', '3.png'],
    texture: '21.png',
    GSM: '240 GSM',
    costStructure: {
      logo: 60,
      LessthenA4: 104,
      A4: 175,
      A3: 250,
     full: 300,
    },
  },
];


const SelectProduct = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const shop = queryParams.get('shop');
  const state = location.state;
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  // Determine if we need to filter products based on the selected color
  const filteredProducts = state?.color
    ? products.filter(product => 
        (state.color === 'white' && product.title === 'whot') ||
        (state.color === 'black' && product.title === 'blot')
      )
    : products;

  const handleProductClick = (product) => {
    if (shop) {
      navigate(`/DesignUpload?shop=${encodeURIComponent(shop)}`, { state: { product, costStructure: product.costStructure, BASE_COST: product.BASE_COST, name: product.name, GSM: product.GSM , tax: product.tax} });
    } else {
      console.error('Shop parameter is missing');
    }
  };

  return (
    <div className="dashboard">
       <Header/>
      <div className="dashboard-container">
      <Sidebar/>
        <div className="main-content">
          <div className="product-list">
            {filteredProducts.map((product) => (
              <div
                key={product.id}
                className="product-card"
                onClick={() => handleProductClick(product)}
              >
                <img src={product.images[0]} alt={product.name} className="product-image" />
                <p className="product-description">{product.description}</p>
                <p className="product-description">{product.fabric}</p>
                <p className="product-description">Base Cost: ₹{product.BASE_COST} + {product.tax} </p>
                <p className="product-description">{product.GSM}</p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SelectProduct;