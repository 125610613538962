import React from 'react';
import Header from '../components/Header';
import Sidebar from '../components/Sidebar';
import '../style/PrivacyPolicy.css'; // Reuse PrivacyPolicy.css for consistency

const ShippingPolicy = () => {
  return (
    <div className="privacy-page">
      <Header />
      <div className="privacy-container">
        <Sidebar />
        <div className="main-content">
          <h1>Shipping Policy</h1>

          <h2>1. Order Processing Time</h2>
          <p>
            All orders placed on our platform are processed within <strong>[1-3 business days]</strong> for confirmation and production. Production times may vary based on product availability and customization requirements.
          </p>

          <h2>2. Shipping Times</h2>
          <p>Once an order has been processed and is ready for shipment, delivery times vary based on the destination:</p>
          <ul>
            <li><strong>Domestic Shipping (within India):</strong> 3-7 business days after production.</li>
            <li><strong>International Shipping:</strong> Currently, our platform supports domestic shipping only. We will expand our reach in the future.</li>
          </ul>

          <h2>3. Tracking Orders</h2>
          <p>
            Upon dispatch, customers will receive a shipping confirmation email that includes a tracking link to monitor the delivery status. Brands and customers can also track their orders directly through the order tracking feature available on our platform.
          </p>

          <h2>4. Failed Deliveries & Returns</h2>
          <p>If a package cannot be delivered due to an incorrect address, unavailability of the recipient, or other reasons, the package will be returned to our facility. Re-shipping fees may apply, depending on the reason for return.</p>
          <p>For products damaged during shipping, customers should reach out to customer support within 48 hours of delivery with a photo of the damaged product to initiate a replacement or refund.</p>

          <h2>5. Delivery Issues</h2>
          <p>We work with trusted shipping providers to ensure timely delivery. However, delays may occur due to factors beyond our control, such as weather conditions, holiday seasons, or unforeseen events. In such cases, we will do our best to keep customers informed.</p>

          <h2>6. Customer Support</h2>
          <p>For any questions or issues related to shipping, customers can contact our support team at <a href="mailto:contact@uniteeculture.com">contact@uniteeculture.com</a>.</p>
        </div>
      </div>
    </div>
  );
};

export default ShippingPolicy;

