import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import { db } from '../firebase';
import { doc, setDoc } from 'firebase/firestore';
import 'react-toastify/dist/ReactToastify.css';
import '../style/DesignReview.css';

const DesignReview = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const {
    frontDesignURL,
    backDesignURL,
    frontMockupURL,
    backMockupURL,
    height,
    width,
    cost,
    userName,
    productName,
  } = location.state || {};

  const [designName, setDesignName] = useState('');
  const [description, setDescription] = useState('');
  const [totalCost, setTotalCost] = useState('');
  const [sellingPrice, setSellingPrice] = useState('');

  const handleSubmit = async () => {
    try {
      const designDocRef = doc(db, 'designs', `${Date.now()}`);
      await setDoc(designDocRef, {
        frontDesignURL,
        backDesignURL,
        frontMockupURL,
        backMockupURL,
        height,
        width,
        cost,
        userName,
        product: productName,
        designName,
        description,
        totalCost,
        sellingPrice,
        createdAt: new Date(),
      });
      toast.success('Design submitted successfully!');
      navigate("/designs"); // Redirect after submission
    } catch (error) {
      console.error('Error submitting design:', error.message);
      toast.error('Failed to submit design. Please try again later.');
    }
  };

  return (
    <div className="design-review-container">
      <ToastContainer />
      <div className="review-content">
        <div className="details">
          <h3>Details</h3>
          <div className="form-group">
            <label>Design Name:</label>
            <input
              type="text"
              value={designName}
              onChange={(e) => setDesignName(e.target.value)}
              required
            />
          </div>
          <div className="form-group">
            <label>Description:</label>
            <input
              type="text"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              required
            />
          </div>
          <div className="form-group">
            <label>Total Cost:</label>
            <input
              type="number"
              value={totalCost}
              onChange={(e) => setTotalCost(e.target.value)}
              required
            />
          </div>
          <div className="form-group">
            <label>Selling Price:</label>
            <input
              type="number"
              value={sellingPrice}
              onChange={(e) => setSellingPrice(e.target.value)}
              required
            />
          </div>
          <button onClick={handleSubmit}>Submit Design</button>
        </div>
        <div className="mockup-preview">
          <h3>Mockups</h3>
          <div>
            <img src={frontMockupURL} alt="Front Mockup" />
            <img src={backMockupURL} alt="Back Mockup" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default DesignReview;
