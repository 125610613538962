// src/firebase.js
import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import { getAuth} from 'firebase/auth';
import { getStorage } from 'firebase/storage';

// Your Firebase configuration object
const firebaseConfig = {
    apiKey: "AIzaSyCFCk_M7_i6tqh7Epi5RHOAAFJdRZG4N58",
    authDomain: "unitee-for-brands.firebaseapp.com",
    projectId: "unitee-for-brands",
    storageBucket: "unitee-for-brands.appspot.com",
    messagingSenderId: "563745314866",
    appId: "1:563745314866:web:c4eb041ecfb8bac2ccc42b",
    measurementId: "G-9F0CY7VSVR"
  };

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const auth = getAuth(app);
const Storage = getStorage(app);



export { db, auth, Storage };
